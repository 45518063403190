import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import erc20Abi from '../config/abis/erc20.json';
import masterChefAbi from '../config/abis/masterchef.json';
import vaultChefAbi from '../config/abis/vaultChef.json';
import vaultStrategyAbi from '../config/abis/vaultStrategy.json';
import { getAddress, getLpAddress } from '../utils/commons';
import vaultInitialState from '../state/vaultInitialState';
import { getSignedContract, getWalletAddress } from './commons';
import { calcApy } from '../utils/farms';

const ZERO = new BigNumber(0);

export const fetchVaults = async () => {
  let erc20Calls = [];
  let masterChefCalls = [];
  let vaultStrategyCalls = [];
  let vaultChefCalls = [];

  const masterChefAddress = getAddress('masterChef');
  const vaultChefAddress = getAddress('vaultChef');

  const erc20BaseCalls = [
    {
      address: getAddress(process.env.REACT_APP_NETWORK_TOKEN),
      name: 'balanceOf',
      params: [getLpAddress(process.env.REACT_APP_NETWORK_TOKEN, process.env.REACT_APP_STABLE_TOKEN)],
    },
    {
      address: getAddress(process.env.REACT_APP_STABLE_TOKEN),
      name: 'balanceOf',
      params: [getLpAddress(process.env.REACT_APP_NETWORK_TOKEN, process.env.REACT_APP_STABLE_TOKEN)],
    },
    {
      address: getAddress(process.env.REACT_APP_NETWORK_TOKEN),
      name: 'decimals',
    },
    {
      address: getAddress(process.env.REACT_APP_STABLE_TOKEN),
      name: 'decimals',
    },
  ];

  const masterChefBaseCalls = [
    {
      address: masterChefAddress,
      name: 'sandManPerSecond',
    },
  ];

  const walletAddress = await getWalletAddress();
  const isUserConnected = walletAddress !== null;

  vaultInitialState.vaults.forEach(vault => {
    const tokenAddress = getAddress(vault.token);
    const quoteTokenAddress = getAddress(vault.quoteToken);
    const lpAddress = getLpAddress(vault.token, vault.quoteToken);

    const calls = [
      {
        address: tokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: quoteTokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: tokenAddress,
        name: 'decimals',
      },
      {
        address: quoteTokenAddress,
        name: 'decimals',
      },
      {
        address: vault.isTokenOnly ? tokenAddress : lpAddress,
        name: 'balanceOf',
        params: [masterChefAddress],
      },
      {
        address: lpAddress,
        name: 'totalSupply',
      },
    ];

    if(isUserConnected) {
      calls.push(
        {
          address: vault.isTokenOnly ? tokenAddress : lpAddress,
          name: 'allowance',
          params: [
            walletAddress,
            vaultChefAddress
          ],
        },
      );
      calls.push(
        {
          address: vault.isTokenOnly ? tokenAddress : lpAddress,
          name: 'balanceOf',
          params: [walletAddress],
        },
      );
    }

    erc20Calls = [...erc20Calls, ...calls];

    const calls2 = [
      {
        address: masterChefAddress,
        name: 'poolInfo',
        params: [vault.farmPid],
      },
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
    ];

    masterChefCalls = [...masterChefCalls, ...calls2];

    vaultStrategyCalls.push({
      address: vault.strategy,
      name: 'wantLockedTotal',
    });

    vaultStrategyCalls.push({
      address: vault.strategy,
      name: 'buyBackAmount',
    });

    if (isUserConnected) {
      vaultChefCalls.push({
        address: vaultChefAddress,
        name: 'stakedWantTokens',
        params: [
          vault.pid,
          walletAddress
        ],
      });
    }
  });

  const erc20Results = await multicall(erc20Abi, [...erc20Calls, ...erc20BaseCalls]);
  const masterChefResults = await multicall(masterChefAbi, [...masterChefCalls, ...masterChefBaseCalls]);
  const vaultStrategyResults = await multicall(vaultStrategyAbi, vaultStrategyCalls);
  const vaultChefResults = vaultChefCalls.length > 0 ? await multicall(vaultChefAbi, vaultChefCalls) : [];

  const erc20Length = (erc20Results.length - erc20BaseCalls.length) / vaultInitialState.vaults.length;
  const masterChefLength = (masterChefResults.length - masterChefBaseCalls.length) / vaultInitialState.vaults.length;
  const vaultStrategyLength = vaultStrategyResults.length / vaultInitialState.vaults.length;
  const vaultChefLength = vaultChefResults.length / vaultInitialState.vaults.length;

  let maxVaultApy = ZERO;
  let tvl = ZERO;
  let nativeTokenPrice = ZERO;
  let totalBuyBackAmount = ZERO;

  const networkTokenBalanceLp = new BigNumber(erc20Results[erc20Results.length - 4]);
  const stableTokenBalanceLp = new BigNumber(erc20Results[erc20Results.length - 3]);
  const networkTokenDecimals = erc20Results[erc20Results.length - 2][0];
  const stableTokenDecimals = erc20Results[erc20Results.length - 1][0];

  const networkTokenPrice = stableTokenBalanceLp
    .times(new BigNumber(10).pow(networkTokenDecimals - stableTokenDecimals))
    .div(networkTokenBalanceLp)

  const tokenPerSecond = new BigNumber(masterChefResults[masterChefResults.length - 1]);

  const newVaults = vaultInitialState.vaults.map((vault, i) => {
    const erc20Index = i * erc20Length;
    const masterChefIndex = i * masterChefLength;
    const vaultStrategyIndex = i * vaultStrategyLength;
    const vaultChefIndex = i * vaultChefLength;

    const tokenBalanceLP = new BigNumber(erc20Results[erc20Index + 0]);
    const quoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 1]);
    const tokenDecimals = erc20Results[erc20Index + 2][0];
    const quoteTokenDecimals = erc20Results[erc20Index + 3][0];
    const lpTokenBalanceMC = new BigNumber(erc20Results[erc20Index + 4]);
    const lpTotalSupply = new BigNumber(erc20Results[erc20Index + 5]);
    const userAllowance = isUserConnected ? new BigNumber(erc20Results[erc20Index + 6]) : ZERO;
    const userBalance =  isUserConnected ? new BigNumber(erc20Results[erc20Index + 7]) : ZERO;
    const allocPoint = new BigNumber(masterChefResults[masterChefIndex + 0].allocPoint._hex);
    const totalAllocPoint = new BigNumber(masterChefResults[masterChefIndex + 1]);
    const wantLockedTotal = new BigNumber(vaultStrategyResults[vaultStrategyIndex + 0]);
    const buyBackAmount = new BigNumber(vaultStrategyResults[vaultStrategyIndex + 1]);
    const stakedWantTokens = isUserConnected ? new BigNumber(vaultChefResults[vaultChefIndex + 0]) : ZERO;

    let [
      farmLpTotalInQuoteToken,
      farmTokenPriceVsQuote,
    ] = vaultInternalCalc(vault, lpTokenBalanceMC, tokenDecimals, quoteTokenBalanceLP, tokenBalanceLP, lpTotalSupply, quoteTokenDecimals);

    let [
      lpTotalInQuoteToken,
      tokenPriceVsQuote,
    ] = vaultInternalCalc(vault, wantLockedTotal, tokenDecimals, quoteTokenBalanceLP, tokenBalanceLP, lpTotalSupply, quoteTokenDecimals);

    if (!vault.isTokenOnly) {
      if (vault.token === process.env.REACT_APP_NATIVE_TOKEN && vault.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        if (tokenPriceVsQuote.isNaN()) {
          tokenPriceVsQuote = new BigNumber(process.env.REACT_APP_DEFAULT_PRICE);
        }
        nativeTokenPrice = tokenPriceVsQuote;
      }
    }
    const poolWeight = allocPoint.div(totalAllocPoint);

    return {
      ...vault,
      farmLpTotalInQuoteToken: farmLpTotalInQuoteToken
        .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        .toJSON(),
      farmTokenPriceVsQuote: farmTokenPriceVsQuote.toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken
        .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        .toJSON(),
      tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
      tokenDecimals,
      quoteTokenDecimals,
      userAllowance: userAllowance.toJSON(),
      userBalance: userBalance.toJSON(),
      allocPoint: allocPoint.toJSON(),
      totalAllocPoint: totalAllocPoint.toJSON(),
      tokenPerSecond: tokenPerSecond.toJSON(),
      poolWeight: poolWeight.toJSON(),
      isNative: vault.token === process.env.REACT_APP_NATIVE_TOKEN || vault.quoteToken === process.env.REACT_APP_NATIVE_TOKEN,
      buyBackAmount: buyBackAmount.toJSON(),
      stakedWantTokens: stakedWantTokens.toJSON(),
    };
  });

  const vaultsWithApy = newVaults.map(vault => {
    const tokenRewardPerSecond = new BigNumber(vault.tokenPerSecond || 1)
        .times(vault.poolWeight)
        .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    const tokenRewardPerYear = tokenRewardPerSecond.times(process.env.REACT_APP_SECONDS_PER_YEAR);

    let apr;
    apr = nativeTokenPrice.times(tokenRewardPerYear);

    let total = new BigNumber(vault.farmLpTotalInQuoteToken || 0);
    if (vault.quoteToken === process.env.REACT_APP_NETWORK_TOKEN) {
      total = total.times(networkTokenPrice);
    }

    if (total.gt(0)) {
      apr = apr.div(total);
    }

    let totalValue = new BigNumber(vault.lpTotalInQuoteToken);
    if (totalValue.isNaN()) {
      totalValue = ZERO;
    }
    if (vault.quoteToken === process.env.REACT_APP_NETWORK_TOKEN) {
      totalValue = networkTokenPrice.times(totalValue);
    } else if (vault.quoteToken === process.env.REACT_APP_NATIVE_TOKEN) {
      totalValue = nativeTokenPrice.times(totalValue);
    }

    tvl = tvl.plus(totalValue);

    totalBuyBackAmount = totalBuyBackAmount.plus(vault.buyBackAmount);

    const roi = new BigNumber(calcApy(apr.times(100).toNumber(), Number(process.env.REACT_APP_VAULT_COMPOUND_FREQUENCY), 1, Number(process.env.REACT_APP_VAULT_PERFORMANCE_FEE)));
    const apy = new BigNumber(calcApy(apr.times(100).toNumber(), Number(process.env.REACT_APP_VAULT_COMPOUND_FREQUENCY), 365, Number(process.env.REACT_APP_VAULT_PERFORMANCE_FEE)));

    if (apy.gt(maxVaultApy)) {
      maxVaultApy = apy;
    }

    return {
      ...vault,
      apr: apr.toJSON(),
      roi: roi.toJSON(),
      apy: apy.toJSON(),
      totalValue: totalValue.toJSON(),
    };
  });

  return {
    tvl: tvl.toJSON(),
    maxVaultApy: maxVaultApy.toJSON(),
    totalBuyBackAmount: totalBuyBackAmount.toJSON(),
    vaults: vaultsWithApy,
    firstLoad: false,
  }
};

export const approveVault = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('vaultChef'), ethers.constants.MaxUint256);
}

export const withdrawVault = async (pid, amount) => {
  const vaultChefContract = await getSignedContract('vaultChef', vaultChefAbi);
  return await vaultChefContract.withdraw(pid, amount);
}

export const depositVault = async (pid, amount) => {
  const vaultChefContract = await getSignedContract('vaultChef', vaultChefAbi);
  return await vaultChefContract.deposit(pid, amount);
}

// ---

const vaultInternalCalc = (vault, lpTokenBalanceMC, tokenDecimals, quoteTokenBalanceLP, tokenBalanceLP, lpTotalSupply, quoteTokenDecimals) => {
  let tokenAmount;
  let lpTotalInQuoteToken;
  let tokenPriceVsQuote;

  if (vault.isTokenOnly) {
    tokenAmount = lpTokenBalanceMC.div(new BigNumber(10).pow(tokenDecimals));

    if (vault.token === process.env.REACT_APP_STABLE_TOKEN && vault.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
      tokenPriceVsQuote = new BigNumber(1);
    } else {
      tokenPriceVsQuote = quoteTokenBalanceLP.div(tokenBalanceLP);
    }
    lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
  } else {
    // Ratio in % a LP tokens that are in staking, vs the total number in circulation
    const lpTokenRatio = lpTokenBalanceMC.div(lpTotalSupply);

    // Total value in staking in quote token value
    lpTotalInQuoteToken = quoteTokenBalanceLP
      .div(new BigNumber(10).pow(tokenDecimals))
      .times(new BigNumber(2))
      .times(lpTokenRatio);

    // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
    tokenAmount = tokenBalanceLP.div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio);
    const quoteTokenAmount = quoteTokenBalanceLP
      .div(new BigNumber(10).pow(quoteTokenDecimals))
      .times(lpTokenRatio);

    if (tokenAmount.gt(0)) {
      tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
    } else {
      tokenPriceVsQuote = quoteTokenBalanceLP
        .div(new BigNumber(tokenBalanceLP))
        .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals));
    }
  }

  return [
    lpTotalInQuoteToken,
    tokenPriceVsQuote,
  ];
}
