import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import nftChefAbi from '../config/abis/nftChef.json';
import nftMergeApiAbi from '../config/abis/nftMergeApi.json';
import erc20Abi from '../config/abis/erc20.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import nftAirdropAbi from '../config/abis/nftAirdrop.json';
import nftMergeAbi from '../config/abis/nftMerge.json';
import nftMergeEndlessAbi from '../config/abis/nftMergeEndless.json';
import { getAddress } from '../utils/commons';
import { getContract, getSignedContract, getWalletAddress } from './commons';

import { experienceScale, nftName } from '../utils/nft';

const ZERO = new BigNumber(0);

export const getNfts = async (pids) => {
  if (pids.length === 0) {
    return [];
  }

  const nftMergeApiAddress = getAddress('nftMergeApi');
  const nftChefAddress = getAddress('nftChef');

  let nftMergeApiCalls = [];
  let nftCalls = [];

  const nfts = [];
  const nftMergeApiRequests = [];
  const nftRequests = [];

  for (let index = 0; index < pids.length; index++) {
    const pid = new BigNumber(pids[index]).toString();

    nftMergeApiCalls.push({
      address: nftMergeApiAddress,
      name: 'cards',
      params: [pid],
    });

    nftCalls.push({
      address: nftChefAddress,
      name: 'characters',
      params: [pid],
    });

    nftCalls.push({
      address: nftChefAddress,
      name: 'getApproved',
      params: [pid],
    });

    if ((index === pids.length - 1) || index % 300 === 0) {
      nftMergeApiRequests.push(multicall(nftMergeApiAbi, nftMergeApiCalls));
      nftRequests.push(multicall(nftChefAbi, nftCalls));

      nftMergeApiCalls = [];
      nftCalls = [];
    }
  }

  const nftMergeApiResultsArr = await Promise.all(nftMergeApiRequests);
  const nftResultsArr = await Promise.all(nftRequests);

  const nftMergeApiResults = nftMergeApiResultsArr.flat();
  const nftResults = nftResultsArr.flat();

  for (let j = 0; j < pids.length; j++) {
    const i = j === 0 ? j : j * 2;

    const pid = new BigNumber(pids[j]).toString();

    const strength = Math.max(new BigNumber(nftMergeApiResults[j].strength._hex).toNumber(), new BigNumber(nftResults[i].strength._hex).toNumber());
    const agility = Math.max(new BigNumber(nftMergeApiResults[j].agility._hex).toNumber(), new BigNumber(nftResults[i].agility._hex).toNumber());
    const endurance = Math.max(new BigNumber(nftMergeApiResults[j].endurance._hex).toNumber(), new BigNumber(nftResults[i].endurance._hex).toNumber());
    const intelligence = Math.max(new BigNumber(nftMergeApiResults[j].intelligence._hex).toNumber(), new BigNumber(nftResults[i].intelligence._hex).toNumber());
    const magic = Math.max(new BigNumber(nftMergeApiResults[j].magic._hex).toNumber(), new BigNumber(nftResults[i].magic._hex).toNumber());
    const wisdom = Math.max(new BigNumber(nftMergeApiResults[j].wisdom._hex).toNumber(), new BigNumber(nftResults[i].wisdom._hex).toNumber());
    const mergeCount = new BigNumber(nftMergeApiResults[j].merge._hex).toNumber();

    const experience = new BigNumber(nftResults[i].experience._hex).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));
    const generation = new BigNumber(nftResults[i].generation._hex);

    const boostStake = (strength + agility + endurance + intelligence + magic + wisdom + experienceScale(experience)) / 100.0;
    const name = nftName({ generation: generation.toNumber(), pid });
  
    nfts.push({
      pid,
      name,
      strength,
      agility,
      endurance,
      intelligence,
      magic,
      wisdom,
      mergeCount,
      allowance: nftResults[i + 1][0],
      boostStake,
      experience: experience.toJSON(),
      generation: generation.toNumber(),
    });
  }

  return nfts;
}

export const fetchMyNfts = async () => {
  const walletAddress = await getWalletAddress();
  if(walletAddress === null) {
    return [];
  }

  const nftPidsCalls = [];

  const nftChefAddress = getAddress('nftChef');
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const userBalanceRaw = await nftChefContract.balanceOf(walletAddress);
  const userBalance = new BigNumber(userBalanceRaw._hex);

  for (let i = 0; userBalance.gt(i); i++) {
    nftPidsCalls.push({
      address: nftChefAddress,
      name: 'tokenOfOwnerByIndex',
      params: [walletAddress, i],
    });
  }
  const nftPidsResults = await multicall(nftChefAbi, nftPidsCalls);

  const myNfts = await getNfts(nftPidsResults);

  return {
    myNfts,
    firstLoad: false
  };
}

export const fetchNftRanking = async () => {
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const totalBalanceRaw = await nftChefContract.totalSupply();
  const totalBalance = new BigNumber(totalBalanceRaw._hex);

  let items = await getNfts([...Array(totalBalance.toNumber()).keys()]);

  items = items.sort((a, b) => {
    if (new BigNumber(a.experience).gt(b.experience)) {
      return -1;
    }

    if (new BigNumber(a.experience).lt(b.experience)) {
      return 1;
    }

    return 0;
  }).slice(0, 20);

  return { items };
}

export const fetchNftSale = async () => {
  const walletAddress = await getWalletAddress();

  const nftChefAddress = getAddress('nftChef');
  const tokenAddress = getAddress('usdc');

  let nftChefCalls = [];
  let erc20Calls = [];
  let nftSaleCalls = [];

  for (let i = 0; i < 7; i ++) {
    const nftSaleAddress = getAddress(`nftSale${i}`);

    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [nftSaleAddress],
    });

    if (walletAddress !== null) {
      erc20Calls.push({
        address: tokenAddress,
        name: 'allowance',
        params: [
          walletAddress,
          nftSaleAddress,
        ],
      });
    }

    nftSaleCalls.push({
      address: nftSaleAddress,
      name: 'salePrice',
    });
  }

  if (walletAddress !== null) {
    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });

    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
  }

  erc20Calls.push({
    address: tokenAddress,
    name: 'decimals',
  });

  const nftChefResults = await multicall(nftChefAbi, nftChefCalls);
  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);

  const items = [];

  for (let i = 0; i < 7; i++) {
    items.push({
      gen: i,
      nftCardsRemaining: new BigNumber(nftChefResults[i]).toJSON(),
      userAllowance: (walletAddress !== null ? new BigNumber(erc20Results[i]) : ZERO).toJSON(),
      startTime: new BigNumber('1640995200').toJSON(),
      salePrice: new BigNumber(nftSaleResults[i]).toJSON(),
    });
  }

  return {
    items,
    userNftBalance: (walletAddress !== null ? new BigNumber(nftChefResults[nftChefResults.length - 1]) : ZERO).toJSON(),
    userBalance: (walletAddress !== null ? new BigNumber(erc20Results[erc20Results.length - 2]) : ZERO).toJSON(),
    tokenDecimals: erc20Results[erc20Results.length - 1],
    firstLoad: false,
  };
}

export const fetchNftMerge = async () => {
  const walletAddress = await getWalletAddress();

  if (walletAddress === null) {
    return {
      nfts: [],
      firstLoad: false,
      pendingClaim: 999999,
      pending: false,
    };
  }

  const nftPidsCalls = [];

  const nftChefAddress = getAddress('nftChef');
  const nftMergeAddress = getAddress('nftMerge');
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const userBalanceRaw = await nftChefContract.balanceOf(walletAddress);
  const userBalance = new BigNumber(userBalanceRaw._hex);

  const nftMergeCalls = [
    {
      address: nftMergeAddress,
      name: 'pendingClaim',
      params: [walletAddress],
    },
    {
      address: nftMergeAddress,
      name: 'pendingProcess',
      params: [walletAddress],
    },
  ];

  const nftMergeResults = await multicall(nftMergeAbi, nftMergeCalls);

  let step = nftMergeResults[1][0] ? 2 : 1;

  for (let i = 0; userBalance.gt(i); i++) {
    nftPidsCalls.push({
      address: nftChefAddress,
      name: 'tokenOfOwnerByIndex',
      params: [walletAddress, i],
    });
  }

  const pendingClaim = new BigNumber(nftMergeResults[0]);

  const nftPidsResults = await multicall(nftChefAbi, nftPidsCalls);

  if (!pendingClaim.eq(999999)) {
    nftPidsResults.push(pendingClaim);
    step = 3;
  }

  const nfts = await getNfts(nftPidsResults);

  let nftClaim = {};
  if (step === 3) {
    nftClaim = nfts.pop();
  }

  return {
    nfts,
    firstLoad: false,
    pendingClaim: pendingClaim.toJSON(),
    nftClaim,
    pendingProcess: nftMergeResults[1][0],
    step
  };
}

export const fetchNftMergeEndless = async () => {
  const walletAddress = await getWalletAddress();

  if (walletAddress === null) {
    return {
      nfts: [],
      firstLoad: false,
      pendingClaim: 999999,
      pending: false,
    };
  }

  const nftPidsCalls = [];

  const nftChefAddress = getAddress('nftChef');
  const nftMergeEndlessAddress = getAddress('nftMergeEndless');
  const nftChefContract = getContract('nftChef', nftChefAbi);

  const userBalanceRaw = await nftChefContract.balanceOf(walletAddress);
  const userBalance = new BigNumber(userBalanceRaw._hex);

  const nftMergeEndlessCalls = [
    {
      address: nftMergeEndlessAddress,
      name: 'pendingClaim',
      params: [walletAddress],
    },
    {
      address: nftMergeEndlessAddress,
      name: 'pendingProcess',
      params: [walletAddress],
    },
  ];

  const nftMergeEndlessResults = await multicall(nftMergeEndlessAbi, nftMergeEndlessCalls);

  let step = nftMergeEndlessResults[1][0] ? 2 : 1;

  for (let i = 0; userBalance.gt(i); i++) {
    nftPidsCalls.push({
      address: nftChefAddress,
      name: 'tokenOfOwnerByIndex',
      params: [walletAddress, i],
    });
  }

  const pendingClaim = new BigNumber(nftMergeEndlessResults[0]);

  const nftPidsResults = await multicall(nftChefAbi, nftPidsCalls);

  if (!pendingClaim.eq(999999)) {
    nftPidsResults.push(pendingClaim);
    step = 3;
  }

  const nfts = await getNfts(nftPidsResults);

  let nftClaim = {};
  if (step === 3) {
    nftClaim = nfts.pop();
  }

  return {
    nfts,
    firstLoad: false,
    pendingClaim: pendingClaim.toJSON(),
    nftClaim,
    pendingProcess: nftMergeEndlessResults[1][0],
    step
  };
}

export const fetchNftAirdrop = async () => {
  const walletAddress = await getWalletAddress();

  const nftAirdropAddress = getAddress('nftAirdrop');

  const nftAirdropCalls = [
    {
      address: nftAirdropAddress,
      name: 'startTime',
    },
  ];

  if (walletAddress !== null) {
    nftAirdropCalls.push({
      address: nftAirdropAddress,
      name: 'recipients',
      params: [walletAddress],
    });
  }

  const nftAirdropResults = await multicall(nftAirdropAbi, nftAirdropCalls);

  return {
    startTime: new BigNumber(nftAirdropResults[0] || 0).toJSON(),
    userCanClaim: nftAirdropResults[1] ? nftAirdropResults[1][0] : false,
  }
}

export const claimNftAirdrop = async () => {
  const nftAirdropContract = await getSignedContract('nftAirdrop', nftAirdropAbi);
  return await nftAirdropContract.claimSandManAirdrop();
}

export const approveNftSale = async (token, address) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress(address), ethers.constants.MaxUint256);
}

export const buyNftCard = async (amount, address) => {
  const nftSaleContract = await getSignedContract(address, nftSaleAbi);
  return await nftSaleContract.buyNftCard(amount);
}

export const mergeNftCard = async (pids) => {
  const nftMergeContract = await getSignedContract('nftMerge', nftMergeAbi);
  return await nftMergeContract.mergeNftCard(pids.map(pid => Number(pid)));
}

export const claimNftMerged = async () => {
  const nftMergeContract = await getSignedContract('nftMerge', nftMergeAbi);
  return await nftMergeContract.claimNftMerged();
}

export const mergeEndlessNftCard = async (pids) => {
  const nftMergeEndlessContract = await getSignedContract('nftMergeEndless', nftMergeEndlessAbi);
  return await nftMergeEndlessContract.mergeNftCard(pids);
}

export const claimEndlessNftMerged = async () => {
  const nftMergeEndlessContract = await getSignedContract('nftMergeEndless', nftMergeEndlessAbi);
  return await nftMergeEndlessContract.claimNftMerged();
}

export const approveNft = async (address, pid) => {
  const nftChefContract = await getSignedContract('nftChef', nftChefAbi);
  return await nftChefContract.approve(address, pid);
}
