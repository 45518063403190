import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EarnNav from '../earn/components/EarnNav';

import VaultFilter from './components/VaultFilter/VaultFilter';
import VaultRow from './components/VaultRow/VaultRow';

// uncomment
import { VaultsContext } from '../../context/vaults';
import { fetchVaults } from '../../blockchain/vaults';
import './VaultsPage.css';

const VaultsPage = () => {
  const filters = JSON.parse(localStorage.getItem('vaultsFilters'));

  const { vaultState, setVaultState } = useContext(VaultsContext);

  const [stakedOnly, setStakedOnly] = useState(filters ? filters.stakedOnly : false);
  const [search, setSearch] = useState(filters ? filters.search : '');

  const saveFilters = (values) => {
    localStorage.setItem('vaultsFilters', JSON.stringify({
      stakedOnly,
      search,
      ...values,
    }));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-1.jpg)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncVaults = async () => {
      setVaultState(prevState => ({ ...prevState, loading: true }));
      const vaultsResult = await fetchVaults();
      setVaultState(prevState => ({ ...prevState, ...vaultsResult, loading: false }));
    }

    const intervalId = setInterval(syncVaults, 15000);

    syncVaults();

    return () => clearInterval(intervalId);
  }, [setVaultState]);

  const renderVaults = () => {
    if (vaultState.firstLoad && vaultState.loading) {
      return (
        <div className="has-text-centered">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    const filteredVaults = vaultState.vaults.filter(vault => {
      let show = true;
      if (stakedOnly) {
        show = show && new BigNumber(vault.stakedWantTokens || 0).gt(0);
      }
      if (search !== '' && show) {
        show = vault.name.toLowerCase().includes(search.toLowerCase());
      }

      return show;
    });

    return (
      <div className="box p-0 border mb-6">
        { filteredVaults.map((vault, i) => <VaultRow key={ vault.pid } vaultData={ vault } lastItem={ filteredVaults.length === i + 1 } />) }
      </div>
    );
  }

  return (
    <>
      <EarnNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title neon-success is-size-1">Auto-Compounding VAULTS</p>
            <p className="subtitle is-size-3">Exponential Grow, Automatized for you.</p>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <VaultFilter
            totalBuyBackAmount={ vaultState.totalBuyBackAmount }
            stakedOnly={ stakedOnly }
            setStakedOnly={ setStakedOnly }
            search={ search }
            setSearch={ setSearch }
            saveFilters={ saveFilters }
          />
          { renderVaults() }
          <div className="columns is-justify-content-center">
            <div className="box content">
              <ul>
                <li>6.66% of earnings will be used for Kingdoms reward, BuyBack and operation fees.</li>
                <li>Enjoy 0% FEE on Deposit and Withdraw for native tokens.</li>
                <li>Our vaults auto-compound once every 6 hours.</li>
                <li>Vaults rewards in the same crypto as deposited.</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default VaultsPage;
