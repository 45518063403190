const vaultInitialState = {
  loading: false,
  firstLoad: true,
  tvl: 0,
  maxFarmApy: 0,
  totalBuyBackAmount: 0,
  vaults: [
    {
      pid: 1,
      farmPid: 1,
      token: 'death',
      quoteToken: 'matic',
      isTokenOnly: false,
      name: 'DEATH-WMATIC LP',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x6Ec45A82F5909a33e72C725D136BF4Eb716B301B',
      startTime: Number(process.env.REACT_APP_LAUNCH_AT),
    },
    {
      pid: 0,
      farmPid: 0,
      token: 'death',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'DEATH-USDC LP',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x200d931643f841250af36c067B67b2dBF89fe208',
      startTime: Number(process.env.REACT_APP_LAUNCH_AT),
    },
    {
      pid: 7,
      farmPid: 2,
      token: 'death',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DEATH',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x669CC1350c771a6bbd664C2C1eAC9F7dd00921E1',
      startTime: Number(process.env.REACT_APP_LAUNCH_AT),
    },
    {
      pid: 2,
      farmPid: 11,
      token: 'delirium',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DELIRIUM',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x09132ca23E0576aD5579E48Aa9808a89E6013818',
      startTime: Number(process.env.REACT_APP_FARMING_START_AT) + (86400 * 3),
    },
    {
      pid: 3,
      farmPid: 12,
      token: 'despair',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESPAIR',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x6F6eE1970E1581e1C1d65513D57bC47327C0a74a',
      startTime: Number(process.env.REACT_APP_FARMING_START_AT) + (86400 * 3),
    },
    {
      pid: 4,
      farmPid: 13,
      token: 'destruction',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESTRUCTION',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x32b3dc8A09d711215C4830b0Bb51FE7bf8c2DECa',
      startTime: Number(process.env.REACT_APP_FARMING_START_AT) + (86400 * 3),
    },
    {
      pid: 5,
      farmPid: 14,
      token: 'desire',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESIRE',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0x2Ce03F134115C3d4a2A44885Efd7F69e71D8676e',
      startTime: Number(process.env.REACT_APP_FARMING_START_AT) + (86400 * 3),
    },
    {
      pid: 6,
      farmPid: 15,
      token: 'destiny',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESTINY',
      swap: 'QuickSwap',
      from: 'Sandman',
      strategy: '0xeea5E65c3ef068feC94759542Bd8e3a6E16f86d5',
      startTime: Number(process.env.REACT_APP_FARMING_START_AT) + (86400 * 3),
    },
  ],
}

export default vaultInitialState;
