const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x08bdDc6f5Ed8C8B3A472513bD831c61cE0f24804');
contractsMainNet.set('multiCall', '0xEF3d4160E3d193E3d1494a4A9cF9eEA05E63a324');

contractsMainNet.set('vaultChef', '0x42079d4EB621360779Cf394A82B8870f5C09F4D1');
contractsMainNet.set('nftChef', '0x743F554f6AcCd4E452AF6C96c48B78E849d87316');
// contractsMainNet.set('nftChef', '0x2487DF8D510cC0A9ec99fcE1F1d43adb3F6C731d'); // TEST
contractsMainNet.set('nftMergeApi', '0xbC50873a9C692A0aB7df352272Ec2aC297EA294a');
// contractsMainNet.set('nftMergeApi', '0x967287128b06ed601d57bF0497D353eB421708be'); // TEST
contractsMainNet.set('nftMerge', '0xBd235282E6d84e27ACD7b8D86558F8923fAe0165');
contractsMainNet.set('nftMergeEndless', '0x30371441B5a7D286D5B923dffA3A2A19261D316A');

contractsMainNet.set('nftSale0', '0xb58175a4a6293eDBe24Db9353F7Bf513c7e84399');
contractsMainNet.set('nftSale1', '0xf5c5Ef6b0929cec517E5F9Cfc214B719c6b526F4');
contractsMainNet.set('nftSale2', '0xa3197704C42Dc5bC9B5337AeEFE1D5C00BBCEd08');
contractsMainNet.set('nftSale3', '0xA7200d2672ae00452448540f6fB3fF7D40F6afD8');
contractsMainNet.set('nftSale4', '0xD6Ef4474e916f16bc86d32A9F88e09733920Fdd7');
contractsMainNet.set('nftSale5', '0xB5036d016ee14BB50BeC3686A08Ae668ff823707');
contractsMainNet.set('nftSale6', '0xBF8dB3Da2F1ebAB194945286a7236bDa8720f3C6');

contractsMainNet.set('nftAirdrop', '0x6C1717118ac69b030468B843A56fcec89F85d061');

contractsMainNet.set('life', '0x3e04C95c9CfF6E565f4D52F08ceCDAc910D91407');
contractsMainNet.set('lifeSwap', '0x5534B902bc4f7d56333a39c7D002297052f172F5');
// single tokens
contractsMainNet.set('death', '0xb95289ED25C5765762E978F0c766818a05456551');
contractsMainNet.set('matic', '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270');
contractsMainNet.set('dai', '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063');
contractsMainNet.set('usdc', '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174');
contractsMainNet.set('usdt', '0xc2132d05d31c914a87c6611c10748aeb04b58e8f');
contractsMainNet.set('mai', '0xa3fa99a148fa48d14ed51d610c367c61876997f1');
contractsMainNet.set('delirium', '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8');
contractsMainNet.set('despair', '0x898F53e0365b3e8114227Df4E811766afCA960b8');
contractsMainNet.set('destruction', '0xCa4992F01B63C7cEB98505946b79D7D8855449F9');
contractsMainNet.set('desire', '0xfbbEa521578059D8c2D53899E44c5A68b8ee88D8');
contractsMainNet.set('destiny', '0x214188993b36bD8cD15f805Adee9eb49fD80BA74');
// LPs
contractsMainNet.set('death-matic', '0x07a5B2F5275cf61a5C7a5B766988f5cF30C9c42B');
contractsMainNet.set('death-usdc', '0xA7E340Eead5DFF3752c4818aE1AeeEB7b81b78d1');
contractsMainNet.set('matic-usdc', '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827');
contractsMainNet.set('usdt-usdc', '0x2cF7252e74036d1Da831d11089D326296e64a728');
contractsMainNet.set('mai-usdc', '0x160532D2536175d65C03B97b0630A9802c274daD');
// LPs utilities
contractsMainNet.set('dai-matic', '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE');
contractsMainNet.set('mai-matic', '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954');
contractsMainNet.set('delirium-usdc', '0x2F051dB1634eEa2081590860ab8E756D452f2239');
contractsMainNet.set('despair-usdc', '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF');
contractsMainNet.set('destruction-usdc', '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1');
contractsMainNet.set('desire-usdc', '0x5C2d9217aeF2B7478F3Cf301619CAd236c345567');
contractsMainNet.set('destiny-usdc', '0x1052c59e067Ca92A261F9813A92900e8eFAE514d');
// Fake
contractsMainNet.set('life-matic', '0x07a5B2F5275cf61a5C7a5B766988f5cF30C9c42B'); // for Kingdom
// contractsMainNet.set('fdai', '0xf53Bed24A75ef612a28CF5520f6a1A4E58282DdC');
// contractsMainNet.set('fdai-matic', '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE');
// contractsMainNet.set('despair-matic', '0xA8F74cebDeB6525BbF447B5e76443e4C46F3F7C3');

// contractsMainNet.set('fusdc', '0x1092eE157Cc686972aC025C798343bed573D11Be');

export { contractsMainNet, contractsTestNet };
