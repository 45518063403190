import React, { useState, useContext } from 'react';

import { WalletContext } from '../../../context/wallet';
import { withdrawPoolNft } from '../../../blockchain/pools';
import { ToastContext } from '../../../context/toast';

const PoolStakeSection = ({ poolData, handleDepositNftModalOpen, handleNftPreviewModalOpen, started, ended }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);

  const handleWithdrawNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await withdrawPoolNft(poolData.address);
      await tx.wait();
      addToast('NFT Card removed', 'is-success');
      setPendingTx(false);
    } catch (error) {
      tx = { error: error.data ? error.data.message : error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Card remove failed', 'is-danger');
      setPendingTx(false);
    }
  }

  const renderUnlockSelectOrRemove = () => {
    if (walletAddress === null) {
      return (
        <div className="level-item">
          <button className="button is-success" onClick={ handleConnectClick }>
            Unlock
          </button>
        </div>
      );
    }

    if (!poolData.userHasNFT) {
      return (
        <button
          disabled={ ended }
          className="button is-success"
          onClick={ () => handleDepositNftModalOpen(poolData) }
        >
          SELECT
        </button>
      );
    }

    return (
      <button
        className={ `button is-danger is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        disabled={ pendingTx }
        onClick={ handleWithdrawNft }
      >
        Remove
      </button>
    );
  }

  const renderNftName = () => {
    if (!poolData.userHasNFT) {
      return (<h5 className="is-size-5 has-text-weight-semibold">Select your NFT</h5>);
    }

    return (
      <h5
        className="is-size-5 has-text-weight-semibold neon-success is-clickable"
        onClick={ () => handleNftPreviewModalOpen(poolData.selectedNft) }
      >
        { poolData.selectedNft.name }
      </h5>
    );
  }

  return (
    <>
      <small>STAKED <span className="has-text-success">NFT Card</span></small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            { renderNftName() }
          </div>
        </div>
        <div className="level-right">
          { renderUnlockSelectOrRemove() }
        </div>
      </div>
    </>
  );
}

export default PoolStakeSection;
