import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EarnNav = () => {
  const location = useLocation();

  return (
    <nav className="section pt-3 pb-0 is-hidden-mobile">
      <div className="container">
        <div className="tabs is-boxed is-centered">
          <ul>
            <li className={ location.pathname === '/farms' ? 'is-active' : '' }>
              <Link to="/farms">
                Farms
                (<span className="has-text-warning">finished</span>)
              </Link>
            </li>
            <li className={ location.pathname === '/pools' ? 'is-active' : '' }>
              <Link to="/pools">
                Pools
                (<span className="has-text-warning">finished</span>)
              </Link>
            </li>
            <li className={ location.pathname === '/kingdoms' ? 'is-active' : '' }>
              <Link to="/kingdoms">
                Kingdoms
              </Link>
            </li>
            <li className={ location.pathname === '/vaults' ? 'is-active' : '' }>
              <Link to="/vaults">
                Vaults
                (<span className="has-text-warning">finished</span>)
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default EarnNav;
