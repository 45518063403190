import React, { useState, useContext, useEffect } from 'react';
import BigNumber from 'bignumber.js';

import { WalletContext } from '../../../context/wallet';
import { withdrawFarmNft } from '../../../blockchain/farms';
import { ToastContext } from '../../../context/toast';

const FarmStakeSection = ({ farmData, handleDepositNftModalOpen, handleNftPreviewModalOpen }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [started, setStarted] = useState(false);
  const [pendingTx, setPendingTx] = useState(false);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const handleWithdrawNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await withdrawFarmNft(farmData.pid);
      await tx.wait();
      addToast('NFT Card removed', 'is-success');
      setPendingTx(false);
    } catch (error) {
      tx = { error: error.data ? error.data.message : error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Card remove failed', 'is-danger');
      setPendingTx(false);
    }
  }

  const renderUnlockSelectOrRemove = () => {
    if (walletAddress === null) {
      return (
        <div className="level-item">
          <button className="button is-primary" onClick={ handleConnectClick }>
            Unlock
          </button>
        </div>
      );
    }

    if (!farmData.hasNFT) {
      return (
        <button
          disabled={ !started }
          className="button is-primary"
          onClick={ () => handleDepositNftModalOpen(farmData) }
        >
          SELECT
        </button>
      );
    }

    return (
      <button
        className={ `button is-danger is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        disabled={ pendingTx }
        onClick={ handleWithdrawNft }
      >
        Remove
      </button>
    );
  }

  const renderNftName = () => {
    if (!farmData.hasNFT) {
      return (<h5 className="is-size-5 has-text-weight-semibold">Select your NFT</h5>);
}

    return (
      <h5
        className="is-size-5 has-text-weight-semibold neon-success is-clickable"
        onClick={ () => handleNftPreviewModalOpen(farmData.selectedNft) }
      >
        { farmData.selectedNft.name }
      </h5>
    );
  }

  return (
    <>
      <small>STAKED <span className="has-text-success">NFT Card</span></small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            { renderNftName() }
          </div>
        </div>
        <div className="level-right">
          { renderUnlockSelectOrRemove() }
        </div>
      </div>
    </>
  );
}

export default FarmStakeSection;
