import { useState, useContext } from "react";
import BigNumber from 'bignumber.js';
import  { withdrawFarm } from '../../../blockchain/farms';
import { trunc } from '../../../utils/commons';

import { ToastContext } from '../../../context/toast';

const FarmWithdrawModal = ({ farmData, isModalActive, onModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');

  const stakedBalance = (mul = 1) => {
    const decimals = farmData.isTokenOnly ? farmData.tokenDecimals : process.env.REACT_APP_DECIMALS;

    return trunc(farmData.stakedBalance, mul, decimals);
  }

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const canWithdraw = () => {
    const value = new BigNumber(val);

    return !value.isNaN() && value.gt(0) && value.lte(stakedBalance());
  }

  const handleWithdraw = async () => {
    const decimals = farmData.isTokenOnly ? farmData.tokenDecimals : process.env.REACT_APP_DECIMALS;
    const amount = new BigNumber(val).times(new BigNumber(10).pow(decimals));
    let tx;
    try {
      setPendingTx(true);
      tx = await withdrawFarm(farmData.pid, amount.toFixed());
      await tx.wait();
      addToast(`${farmData.isTokenOnly ? '' : 'LP '}Token Withdraw succeeded`, 'is-success');
      setPendingTx(false);
      onModalClose();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast(`${farmData.isTokenOnly ? '' : 'LP '}Token Withdraw failed`, 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Withdraw { farmData.name }</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-right mb-0">
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.25).toFixed()) }>
              25%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.5).toFixed()) }>
              50%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.75).toFixed()) }>
              75%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance().toFixed()) }>
              100%
            </button>
            <button className="button is-text" onClick={ () => setValue(stakedBalance().toFixed()) }>
              { stakedBalance().toFormat() } max
            </button>
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
            </div>
            <div className="control">
              <button type="button" className="button is-black">
                { farmData.name }
              </button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            onClick={ handleWithdraw }
            disabled={ pendingTx || !canWithdraw() }
            className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
          >
            Confirm
          </button>
          <button className="button" onClick={ onModalClose }>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default FarmWithdrawModal;
