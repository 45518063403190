import React, { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { harvestFarm } from '../../../blockchain/farms';
import { addToMetamask } from '../../../blockchain/commons';
import { getAddress, nativeTokenName } from '../../../utils/commons';

import { ToastContext } from '../../../context/toast';

const ZERO = new BigNumber(0);

const FarmsStakingSection = ({ farmState }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);

  const earningPids = [];

  let earnings = ZERO;
  let nativeTokenBalance = ZERO;
  let nativeTokenBalanceStable = ZERO;
  let decimals = new BigNumber(10).pow(process.env.REACT_APP_DECIMALS);

  for(let i = 0; i < farmState.farms.length; i++) {
    const farm = farmState.farms[i];
    const pendingToken = new BigNumber(farm.pendingToken);
    if (pendingToken.gt(0)) {
      earningPids.push(farm.pid);
      earnings = earnings.plus(pendingToken);
    }
    if (farm.token === process.env.REACT_APP_NATIVE_TOKEN) {
      decimals = new BigNumber(10).pow(farm.tokenDecimals)
      nativeTokenBalance = new BigNumber(farm.userBalance).div(decimals);
      nativeTokenBalanceStable = nativeTokenBalance.times(farmState.nativeTokenPrice);
    }
  }
  earnings = earnings.div(decimals);
  const earningsStable = earnings.times(farmState.nativeTokenPrice);

  const handleAddToMetamask = () => {
    const address = getAddress(process.env.REACT_APP_NATIVE_TOKEN)
    const symbol = nativeTokenName;
    const decimals = 18;
    const image = 'https://death.sandman.finance/images/death.png';

    addToMetamask(address, symbol, decimals, image);
  }

  const handleHarvestAll = async () => {
    setPendingTx(true);

    const harvest = async (pid) => {
      const tx = await harvestFarm(pid);
      await tx.wait();
    }

    const harvestPromises = earningPids.reduce((accum, pid) => {
      return [...accum, harvest(pid)];
    }, []);
    await Promise.all(harvestPromises);

    addToast('Harvest success!', 'is-success');

    setPendingTx(false);
  }

  return (
    <div className="box is-flex-grow-1">
      <h3 className="title has-text-info">Farms &amp; Staking</h3>
      <div className="is-flex is-align-items-center mb-5">
        <figure className="image is-64x64 mr-3">
          <img src={ `/images/farms/${process.env.REACT_APP_NATIVE_TOKEN}.png` } alt={ nativeTokenName } />
        </figure>
        {farmState.nativeTokenPriceDefault ? null : (
          <button onClick={ handleAddToMetamask } type="button" className="button is-primary">
            <span>add to </span>
            <figure className="image is-32x32">
              <img src="/images/metamask.svg" alt={ nativeTokenName } />
            </figure>
          </button>
        )}
      </div>
      <h5 className="subtitle">{ nativeTokenName } in Wallet</h5>
      <h3 className="title is-4">
        { farmState.firstLoad ? (
          <FontAwesomeIcon icon={ faCog } spin />
        ) : (
          nativeTokenBalance.toFormat(2)
        ) }
      </h3>
      <h4 className="subtitle is-6">
        { farmState.firstLoad ? (
          <FontAwesomeIcon icon={ faCog } spin />
        ) : (
          `~$ ${nativeTokenBalanceStable.toFormat(2)}`
        ) }
      </h4>
      <h5 className="subtitle mb-0">{ nativeTokenName } to Harvest</h5>
      <div className="is-flex is-align-items-flex-end is-justify-content-space-between">
        <div>
          <h3 className="title is-4">
            { farmState.firstLoad ? (
              <FontAwesomeIcon icon={ faCog } spin />
            ) : (
              earnings.toFormat(2)
            ) }
          </h3>
          <h4 className="subtitle is-6">
            { farmState.firstLoad ? (
              <FontAwesomeIcon icon={ faCog } spin />
            ) : (
              `~$ ${earningsStable.toFormat(2)}`
            ) }
          </h4>
        </div>
        <button
          type="button"
          className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
          disabled={ farmState.firstLoad || pendingTx || earnings.lte(0) }
          onClick={ handleHarvestAll }
        >
          Harvest All
        </button>
      </div>
    </div>
  );
}

export default FarmsStakingSection;
