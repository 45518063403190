import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NftNav = () => {
  const location = useLocation();

  return (
    <nav className="section pt-3 pb-0 is-hidden-mobile">
      <div className="container">
        <div className="tabs is-boxed is-centered">
          <ul>
            <li className={ location.pathname === '/my-nfts' ? 'is-active' : '' }>
              <Link to="/my-nfts">My NFTs</Link>
            </li>
            <li className={ location.pathname === '/nft-merge' ? 'is-active' : '' }>
              <Link to="/nft-merge">
                Merge
              </Link>
            </li>
            <li className={ location.pathname === '/nft-merge-endless' ? 'is-active' : '' }>
              <Link to="/nft-merge-endless">
                Merge "The Endless"
              </Link>
            </li>
            <li className={ location.pathname === '/nft-sale' ? 'is-active' : '' }>
              <Link to="/nft-sale">
                Sale
              </Link>
            </li>
            <li className={ location.pathname === '/nft-airdrop' ? 'is-active' : '' }>
              <Link to="/nft-airdrop">
                Airdrop
              </Link>
            </li>
            <li className={ location.pathname === '/nft-ranking' ? 'is-active' : '' }>
              <Link to="/nft-ranking">Ranking</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NftNav;
