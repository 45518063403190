import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import  { harvestFarm, depositFarm } from '../../../blockchain/farms';

import { nativeTokenName } from '../../../utils/commons';

import { ToastContext } from '../../../context/toast';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ days, hours, minutes }) => {
  return (
    <span>
      { days > 0 ? `${days} days ` : '' }{ pad(hours) }:{ pad(minutes) }
    </span>
  );
};

const FarmHarvestSection = ({ farmData, nativeTokenPrice }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const updateTime = () => {
      const currentTime = Date.now() / 1000;
      const nextHarvestUntil = new BigNumber(farmData.nextHarvestUntil);

      setEnabled(prevState => nextHarvestUntil.lt(currentTime));
    }

    const intervalId = setInterval(updateTime, 1000);

    updateTime();

    return () => clearInterval(intervalId);
  }, [farmData.nextHarvestUntil, setEnabled]);

  const earned = () => {
    const earnedValue = new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const earnedUsd = () => {
    const earnedValue = new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS))
      .times(nativeTokenPrice);

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const canHarvest = useMemo(() => {
    return new BigNumber(farmData.pendingToken).gt(0) && enabled;
  }, [farmData.pendingToken, enabled]);

  const handleHarvest = async () => {
    let tx;
    try {
      setPendingHarvestTx(true);
      tx = await harvestFarm(farmData.pid);
      await tx.wait();
      addToast('Harvest succeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Harvest failed', 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const handleCompound = async () => {
    setPendingHarvestTx(true);
    let tx;
    try {
      tx = await depositFarm(farmData.pid, new BigNumber(farmData.pendingToken).toFixed());
      await tx.wait();
      addToast('Successfull Compound!', 'is-success');
    } catch(error) {
      tx = { error: error.data?.message || error.message };
    }
    if(tx.error !== undefined) {
      addToast(tx.error, 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const renderHarvestOrCountDown = useMemo(() => {
    if (enabled) {
      return <>HARVEST</>;
    }

    return <Countdown date={ new BigNumber(farmData.nextHarvestUntil).times(1000).toNumber() } />
  }, [farmData.nextHarvestUntil, enabled]);

  const harvestLockup = useCallback(() => {
    let lockup = new BigNumber(farmData.harvestInterval);

    if (farmData.hasNFT) {
      const experience = new BigNumber(farmData.selectedNft.experience);
      lockup = lockup.times(0.75).minus(experience.div(10));
    }

    return (
      <>
        <span className={ `has-text-danger ${farmData.hasNFT ? 'is-line-through mr-1' : ''}` }>
          <Countdown date={ new BigNumber(farmData.harvestInterval).times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
        </span>
        {farmData.hasNFT ? (
          <span className="has-text-warning">
            <Countdown date={ lockup.times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
          </span>
        ) : null}
      </>
    );
  }, [farmData.harvestInterval, farmData.hasNFT, farmData.selectedNft?.experience]);

  return (
    <>
      <small className="is-flex is-justify-content-space-between">
        <span><span className="has-text-success">{ nativeTokenName }</span> EARNED</span>
        <span>LOCKUP { harvestLockup() }</span>
      </small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { earned() }<br />
              ~${ earnedUsd() }
            </h5>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              {farmData.name.toLowerCase() === process.env.REACT_APP_NATIVE_TOKEN ? (
                <button
                  disabled={ pendingHarvestTx || !canHarvest }
                  className={ `button is-primary mb-1 ${pendingHarvestTx ? 'is-loading' : ''}` }
                  onClick={ handleCompound }
                >
                  COMPOUND
                </button>
              ) : null}
              <button
                disabled={ pendingHarvestTx || !canHarvest }
                className={ `button is-primary ${pendingHarvestTx ? 'is-loading' : ''}` }
                onClick={ handleHarvest }
              >
                { renderHarvestOrCountDown }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FarmHarvestSection;
