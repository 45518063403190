import React, { useState, useContext, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { experienceScale, nftName } from '../../utils/nft';

import NftNav from './components/NftNav';
import NftMergeCard from './components/NftMergeCard';
import NftInfo from './components/NftInfo';
import { ToastContext } from '../../context/toast';

import { fetchNftMergeEndless, claimEndlessNftMerged, mergeEndlessNftCard } from '../../blockchain/nft';

const NftMergeEndlessPage = () => {
  const { addToast } = useContext(ToastContext);
  const [filter, setFilter] = useState('to-merge');
  const [generations, setGenerations] = useState([]);
  const [selectedCards, setSelectedCards] = useState({
    count: 0,
    items: []
  });
  const [pendingTx, setPendingTx] = useState(false);
  const [started, setStarted] = useState(false);
  const [nftMergeState, setNftMergeState] = useState({
    loading: false,
    nfts: [],
    firstLoad: true,
    pendingClaim: 999999,
    pendingProcess: false,
    nftClaim: {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-2.png)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncNftMerge = async () => {
      setNftMergeState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchNftMergeEndless();
      setNftMergeState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncNftMerge, 10000);

    syncNftMerge();

    return () => clearInterval(intervalId);
  }, [setNftMergeState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;

      setStarted(prevState => prevState || Number(process.env.REACT_APP_NFT_MERGE_ENDLESS_START) < currentTime);
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const toggleCardId = (cardId, newGeneration) => {
    const prevState = selectedCards;
    const idx = prevState.items.indexOf(cardId);
    if (idx > -1) {
      prevState.items.splice(idx, 1);
    } else {
      prevState.items.push(cardId);
    }
    const gens = [...generations];
    const idx2 = gens.indexOf(newGeneration);
    if (idx2 > -1) {
      gens.splice(idx2, 1);
    } else {
      gens.push(newGeneration);
    }
    setGenerations(prevState => [...gens]);

    prevState.count = prevState.items.length;
    setSelectedCards(oldState => ({ oldState, ...prevState }));
  }

  const handleClaimNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await claimEndlessNftMerged();
      await tx.wait();
      addToast('Merged NFT claim succeeded', 'is-success');
      showConfetti();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Merged NFT claim failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleMergeNftCard = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await mergeEndlessNftCard(selectedCards.items);
      await tx.wait();
      addToast('NFT Merge succeeded', 'is-success');
      setGenerations([]);
      setSelectedCards({
        items: [],
        count: 0,
      });
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Merge failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const showConfetti = () => {
    confetti({
      resize: true,
      particleCount: 200,
      startVelocity: 30,
      gravity: 0.5,
      spread: 350,
      origin: {
        x: 0.5,
        y: 0.3,
      },
    });
  }

  const isLoading = () => nftMergeState.firstLoad && nftMergeState.loading;

  const filteredNfts = () => {
    let newNfts = nftMergeState.nfts;

    if (filter === 'to-merge') {
      newNfts = newNfts.filter(nft => new BigNumber(nft.generation).lt(7));
    }

    if (filter === 'the-endless') {
      newNfts = newNfts.filter(nft => new BigNumber(nft.generation).eq(7));
    }

    return newNfts;
  }

  const renderResults = () => {
    if (isLoading()) {
      return (
        <div className="columns is-justify-content-center">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    if (nftMergeState.step !== 1) {
      return null;
    }

    if (nftMergeState.nfts.length > 0) {
      return (
        <>
          <div className="tabs is-toggle">
            <ul>
              <li className={ filter === 'to-merge' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'to-merge') {
                      setFilter('to-merge');
                    }
                  }}
                >
                  To Merge
                </a>
              </li>
              <li className={ filter === 'the-endless' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'the-endless') {
                      setFilter('the-endless');
                    }
                  }}
                >
                  The Endless
                </a>
              </li>
              <li className={ filter === 'all' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'all') {
                      setFilter('all');
                    }
                  }}
                >
                  All
                </a>
              </li>
            </ul>
          </div>
          <div className="columns is-multiline is-justify-content-center">
            {filteredNfts().map(nftData => (
              <div key={ `nft-${nftData.generation}-${nftData.pid}` } className="column is-half">
                <NftMergeCard
                  nftData={ nftData }
                  toggleCardId={ toggleCardId }
                  selected={ selectedCards.items.includes(nftData.pid) }
                  disabled={ !started || selectedCards.count >= 7 || ((generations || []).includes(new BigNumber(nftData.generation).toNumber())) }
                  from="nftMergeEndless"
                />
              </div>
            ))}
          </div>
        </>
      );
    }

    return (
      <NftMergeCard
        nftData={{
          pid: 2110,
          name: nftName({ generation: 7, pid: 2110 }),
          experience: 1920,
          generation: 7,
          boostStake: 287,
          mergedCount: 0
        }}
        ribbon="ON SALE"
        hideActions
        from="nftMergeEndless"
      />
    );
  }

  const preview = () => {
    let pid = 999999;
    let experience = new BigNumber(0);
    let generation = 7;
    let strengthArr = [];
    let agilityArr = [];
    let enduranceArr = [];
    let intelligenceArr = [];
    let magicArr = [];
    let wisdomArr = [];
    let mergeCount = 0;

    for (let i = 0; i < selectedCards.count; i++) {
      const card = nftMergeState.nfts.find(nft => nft.pid === selectedCards.items[i]);
      if (i === 0) {
        pid = card.pid;
      }
      experience = experience.plus(card.experience);
      strengthArr.push(card.strength);
      agilityArr.push(card.agility);
      enduranceArr.push(card.endurance);
      intelligenceArr.push(card.intelligence);
      magicArr.push(card.magic);
      wisdomArr.push(card.wisdom);
    }

    let strength = 0;
    if (strengthArr.length > 0) {
      const avg = strengthArr.reduce((a, b) => a + b, 0) / strengthArr.length;
      strength = Math.max(...strengthArr) + Math.floor(avg / 2);
    }
    let agility = 0;
    if (agilityArr.length > 0) {
      const avg = agilityArr.reduce((a, b) => a + b, 0) / agilityArr.length;
      agility = Math.max(...agilityArr) + Math.floor(avg / 2);
    }
    let endurance = 0;
    if (enduranceArr.length > 0) {
      const avg = enduranceArr.reduce((a, b) => a + b, 0) / enduranceArr.length;
      endurance = Math.max(...enduranceArr) + Math.floor(avg / 2);
    }
    let intelligence = 0;
    if (intelligenceArr.length > 0) {
      const avg = intelligenceArr.reduce((a, b) => a + b, 0) / intelligenceArr.length;
      intelligence = Math.max(...intelligenceArr) + Math.floor(avg / 2);
    }
    let magic = 0;
    if (magicArr.length > 0) {
      const avg = magicArr.reduce((a, b) => a + b, 0) / magicArr.length;
      magic = Math.max(...magicArr) + Math.floor(avg / 2);
    }
    let wisdom = 0;
    if (wisdomArr.length > 0) {
      const avg = wisdomArr.reduce((a, b) => a + b, 0) / wisdomArr.length;
      wisdom = Math.max(...wisdomArr) + Math.floor(avg / 1.25);
    }

    const boostStake = (strength + agility + endurance + intelligence + magic + wisdom + experienceScale(experience)) / 100.0;

    return {
      pid,
      name: nftName({ generation, pid }),
      experience: experience.toJSON(),
      generation,
      strength,
      agility,
      endurance,
      intelligence,
      magic,
      wisdom,
      mergeCount,
      boostStake,
    }
  }

  return (
    <>
      <NftNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title neon-success is-size-1">Merge NFT Cards</p>
            <p className="subtitle is-size-3"><span className="has-text-success">NFT Cards</span> power will be unique and generated randomly.</p>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          {started ? null : (
            <div className="box has-text-centered">
              <p className="title has-text-primary">
                <Countdown date={ new BigNumber(process.env.REACT_APP_NFT_MERGE_ENDLESS_START).times(1000).toNumber() } />
              </p>
              <p className="subtitle">
                Countdown until start
              </p>
            </div>
          )}
          <div className="columns">
            <div className="column">
              <div className="box content">
                <h4>Merge Steps</h4>
                <ol>
                  <li className={ nftMergeState.step === 1 ? 'has-text-success' : '' }>
                    Select one NFT Card of each generation to Merge {nftMergeState.step === 1 ? `${selectedCards.count}` : ''}
                  </li>
                  <li className={ nftMergeState.step === 2 ? 'has-text-success' : '' }>
                    <span className="icon-text">
                      Please wait while we are merging.
                      {nftMergeState.step === 2 ? (
                        <span className="icon ml-2">
                          <FontAwesomeIcon icon={ faCog } spin />
                        </span>
                      ) : null}
                    </span>
                  </li>
                  <li className={ nftMergeState.step === 3 ? 'has-text-success' : '' }>
                    Congrats! You can claim your new NFT Card Boosted.
                  </li>
                </ol>
                <h4>How it works</h4>
                <ul>
                  <li>You'll be able to merge 7 Cards at a time.</li>
                  <li>Select your Cards and see in real time how the new one boosted will be look.</li>
                  <li>A Card can only be merged once. This mean that once you get a merged Card, it wont be eligible to merge again.</li>
                  <li>One card by generation.</li>
                </ul>
              </div>
            </div>
            {nftMergeState.step === 1 ? (
              <div className="column is-half">
                <NftMergeCard
                  nftData={ preview() }
                  hideActions
                  from="nftMergeEndless"
                />
                <button
                  type="button"
                  // disabled={ pendingTx || selectedCards.count < 7 }
                  disabled
                  onClick={ handleMergeNftCard }
                  className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
                >
                  V2 is coming...
                </button>
              </div>
            ) : null}
            {nftMergeState.step === 3 ? (
              <div className="column is-half">
                <NftMergeCard
                  nftData={ nftMergeState.nftClaim }
                  hideActions
                  from="nftMergeEndless"
                />
                <button
                  type="button"
                  disabled={ pendingTx }
                  onClick={ handleClaimNft }
                  className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
                >
                  Claim
                </button>
              </div>
            ) : null}
          </div>
          { renderResults() }
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <NftInfo />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NftMergeEndlessPage;
