import React from 'react';

const NftInfo = () => {
  return (
    <div className="box content">
      <h4>WHAT YOU CAN DO WITH YOUR NFT CARD</h4>
      <ul>
        <li>BOOST your YIELD FARMING AND KINGDOM REWARDS</li>
        <li>REDUCE your Harvest Lockup interval</li>
        <li>VOTE for make decisions in our Platform</li>
        <li>GAIN EXPERIENCE as you use the card.</li>
        {/* <li>EPIC BATTLES with other users.</li> */}
        <li>MERGE many cards to create a new BOOSTED one.</li>
      </ul>
    </div>
  );
}

export default NftInfo;
