import React, { useContext, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeNav from '../home/components/HomeNav';
import LifeBuyModal from './LifeBuyModal';

import { fetchPreSale, approveBuyLife, approveSwapLife, swapLifeForDeath } from '../../blockchain/preSale';

import { WalletContext } from '../../context/wallet';
import { ToastContext } from '../../context/toast';

const PresalePage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [lifeStarted, setLifeStarted] = useState(false);
  const [lifeEnded, setLifeEnded] = useState(false);
  const [provideLiquidityStarted, setProvideLiquidityStarted] = useState(false);
  const [lifeSwapStarted, setLifeSwapStarted] = useState(false);
  const [masterChefStarted, setMasterChefStarted] = useState(false);
  const [nftSale0Started, setNftSale0Started] = useState(false);
  // const [nftSale1Started, setNftSale1Started] = useState(false);
  // const [nftSale2Started, setNftSale2Started] = useState(false);
  const [nftAirdropStarted, setNftAirdropStarted] = useState(false);
  // const [kingdom0Started, setKingdom0Started] = useState(false);

  const [pendingBuyTx, setPendingBuyTx] = useState(false);
  const [pendingSwapTx, setPendingSwapTx] = useState(false);

  const [preSaleState, setPreSaleState] = useState({
    firstLoad: true,
    lifeStartTime: 0,
    lifeEndTime: 0,
    lifePerAccountMaxTotal: 0,
    lifeRemaining: 0,
    currentStage: 0,
    salePrice: 0,
    lifeDecimals: 0,
    lifeBalance: 0,
    userLifeSwapAllowance: 0,
    userLifeBalance: 0,
    earlyAccessTwoTime: 0,
    earlyAccessThreeTime: 0,

    lifeSwapStartTime: 0,

    lifeSwapBalance: 0,
    nativeTokenDecimals: 0,
    tokenDecimals: 0,
    userLifeAllowance: 0,
    userTokenBalance: 0,
    userNativeTokenBalance: 0,

    masterChefStartTime: 0,

    nftSale0StartTime: 0,
    // nftSale1StartTime: 0,
    // nftSale2StartTime: 0,

    nftAirdropStartTime: 0,

    soldOut: false,
  });

  const [isLifeBuyModalActive, setIsLifeBuyModalActive] = useState(false);

  const handleLifeBuyModalClose = () => setIsLifeBuyModalActive(false);
  const handleLifeBuyModalOpen = () => setIsLifeBuyModalActive(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-0.jpg)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncPreSale = async () => {
      setPreSaleState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchPreSale();
      setPreSaleState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncPreSale, 5000);

    syncPreSale();

    return () => clearInterval(intervalId);
  }, [setPreSaleState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const lifeStartTime = new BigNumber(preSaleState.lifeStartTime);
      const lifeEndTime = new BigNumber(preSaleState.lifeEndTime);
      const lifeSwapStartTime = new BigNumber(preSaleState.lifeSwapStartTime);
      const masterChefStartTime = new BigNumber(preSaleState.masterChefStartTime);
      const nftSale0StartTime = new BigNumber(preSaleState.nftSale0StartTime);
      // const nftSale1StartTime = new BigNumber(preSaleState.nftSale1StartTime);
      // const nftSale2StartTime = new BigNumber(preSaleState.nftSale2StartTime);
      // const kingdom0StartTime =  new BigNumber(process.env.REACT_APP_WALL_0_START);
      const nftAirdropStartTime = new BigNumber(preSaleState.nftAirdropStartTime);
      const launchApp = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setLifeStarted(prevState => prevState || (lifeStartTime.gt(0) && lifeStartTime.lt(currentTime)));
      setLifeEnded(prevState => prevState || (lifeEndTime.gt(0) && lifeEndTime.lt(currentTime)));
      setLifeSwapStarted(prevState => prevState || (lifeSwapStartTime.gt(0) && lifeSwapStartTime.lt(currentTime)));
      setMasterChefStarted(prevState => prevState || (masterChefStartTime.gt(0) && masterChefStartTime.lt(currentTime)));
      setNftSale0Started(prevState => prevState || (nftSale0StartTime.gt(0) && nftSale0StartTime.lt(currentTime)));
      // setNftSale1Started(prevState => prevState || (nftSale1StartTime.gt(0) && nftSale1StartTime.lt(currentTime)));
      // setNftSale2Started(prevState => prevState || (nftSale2StartTime.gt(0) && nftSale2StartTime.lt(currentTime)));
      // setKingdom0Started(prevState => prevState || (kingdom0StartTime.gt(0) && kingdom0StartTime.lt(currentTime)));
      setNftAirdropStarted(prevState => prevState || (nftAirdropStartTime.gt(0) && nftAirdropStartTime.lt(currentTime)));
      setProvideLiquidityStarted(prevState => prevState || (launchApp.gt(0) && launchApp.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  // }, [preSaleState, setLifeStarted, setLifeEnded, setLifeSwapStarted, setMasterChefStarted, setNftSale1Started, setNftSale2Started, setKingdom0Started]);
  }, [preSaleState, setLifeStarted, setLifeEnded, setLifeSwapStarted, setMasterChefStarted, setNftSale0Started, setNftAirdropStarted]);

  const handleBuyApprove = async () => {
    setPendingBuyTx(true);
    let tx;
    try {
      tx = await approveBuyLife('usdc');
      await tx.wait();
      addToast('Life Token approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Life Token approve failed', 'is-danger');
    }

    setPendingBuyTx(false);
  }

  const renderUnlockOrApproveOrBuyButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = !lifeStarted || lifeEnded;

    if (new BigNumber(preSaleState.userLifeAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading || pendingBuyTx || preSaleState.soldOut || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingBuyTx ? 'is-loading' : ''}` }
          onClick={ handleBuyApprove }
        >
          APPROVE USDC
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading || isNotActive || preSaleState.soldOut }
        className="button is-primary is-fullwidth"
        onClick={ () => handleLifeBuyModalOpen() }
      >
        Buy
      </button>
    );
  }

  const handleSwapApprove = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await approveSwapLife();
      await tx.wait();
      addToast('Life token Swap approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Life token Swap approve failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const handleSwap = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await swapLifeForDeath();
      await tx.wait();
      addToast('Life Swap succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Life Swap failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const renderLifeSwapCountdown = (text) => {
    if (isLoading) {
      return '';
    }

    if (!lifeSwapStarted) {
      return (
        <>
          <span className="mr-1">Start at</span> <Countdown date={ preSaleState.lifeSwapStartTime * 1000 } />
        </>
      );
    }

    return (<>{ text }</>);
  }

  const renderUnlockOrApproveOrSwapButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = !lifeSwapStarted;

    if (new BigNumber(preSaleState.userLifeSwapAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading || pendingSwapTx || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
          onClick={ handleSwapApprove }
        >
          { renderLifeSwapCountdown('APPROVE LIFE') }
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading || pendingSwapTx || isNotActive || new BigNumber(preSaleState.userLifeBalance).eq(0) }
        className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
        onClick={ () => handleSwap() }
      >
        { renderLifeSwapCountdown('SWAP') }
      </button>
    );
  }

  const isLoading = useMemo(() => {
    return preSaleState.firstLoad;
  }, [preSaleState.firstLoad]);

  const renderRemainingOrSoldOut = () => {
    const rm = new BigNumber(preSaleState.lifeRemaining)
      .div(new BigNumber(10).pow(preSaleState.lifeDecimals));

    return rm.eq(0) ? 'SOLD OUT' : rm.toFormat(2);
  }

  const earlyAccessTime = () => {
    const currentStage = new BigNumber(preSaleState.currentStage);

    if (currentStage.eq(3)) {
      return preSaleState.lifeEndTime;
    }

    if (currentStage.eq(2)) {
      return preSaleState.earlyAccessThreeTime;
    }

    return preSaleState.earlyAccessTwoTime;
  }

  const renderLoading = (text = 'Loading...') => {
    return (
      <span className="icon-text is-align-items-center">
        <span className="icon is-large">
          <FontAwesomeIcon icon={ faCog } spin size="2x" />
        </span>
        <span>{ text }</span>
      </span>
    );
  }

  const renderHeroSubtitle = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (!lifeEnded) {
      return (
        <p className="subtitle is-size-3">
          Early Access #{ preSaleState.currentStage } - ${ new BigNumber(preSaleState.salePrice).toFormat(2) }
        </p>
      );
    }
  }

  return (
    <>
      <HomeNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-justify-content-center">
              <div className="column is-two-thirds has-text-centered">
                <p className="title is-size-1 neon-success">DEATH PRESALE</p>
                { renderHeroSubtitle() }
              </div>
            </div>
            {!isLoading && !lifeEnded ? (
              <div className="columns is-justify-content-center">
                <div className="column is-one-third-desktop">
                  <p className="title neon-warning has-text-warning">{ renderRemainingOrSoldOut() }</p>
                  <p className="subtitle">Tokens Remaining</p>
                </div>
                <div className="column is-one-third-desktop">
                  <div className="has-text-right-desktop">
                    <p className="title neon-warning has-text-warning">
                      <Countdown date={ new BigNumber(earlyAccessTime()).plus(0).times(1000).toNumber() } />
                    </p>
                    <p className="subtitle">
                      { lifeStarted ? `Presale ends (early access ${new BigNumber(preSaleState.currentStage).toNumber()})` : 'Countdown until Presale Start' }
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="columns is-justify-content-center is-multiline">
            <div className="column is-one-third">
              <div className={ `box ${!isLoading && !lifeEnded ? 'important-box' : ''}` }>
                <p className="subtitle has-text-info">LIFE PRESALE (early access #{ preSaleState.currentStage })</p>
                <div className="content">
                  <dl>
                    <dt>LIFE Token Remaining (only <span className="has-text-success">${ new BigNumber(preSaleState.salePrice).toFormat(2) }</span> per token):</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ renderRemainingOrSoldOut() }</strong> }</dd>
                    <dt>Your LIFE balance:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ new BigNumber(preSaleState.userLifeBalance).div(new BigNumber(10).pow(preSaleState.lifeDecimals)).toFormat(2) }</strong> }</dd>
                    <dt>Your USDC balance:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">${ new BigNumber(preSaleState.userTokenBalance).div(new BigNumber(10).pow(preSaleState.tokenDecimals)).toFormat(2) }</strong> }</dd>
                    <dt>You can buy up to:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ new BigNumber(preSaleState.lifePerAccountMaxTotal).div(new BigNumber(10).pow(preSaleState.lifeDecimals)).toFormat(2) }</strong> } LIFE</dd>
                  </dl>
                  { renderUnlockOrApproveOrBuyButton() }
                </div>
              </div>
            </div>
            <div className="column is-one-third">
              <div className={ `box ${!isLoading && lifeEnded ? 'important-box' : ''}` }>
                <p className="subtitle has-text-info">DEATH Redeem</p>
                <div className="content">
                  <dl>
                    <dt>DEATH Remaining:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ new BigNumber(preSaleState.lifeSwapBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }</dd>
                    <dt>Your DEATH balance:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ new BigNumber(preSaleState.userNativeTokenBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }</dd>
                    <dt>You can swap for up to:</dt>
                    <dd>{ isLoading ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-success">{ new BigNumber(preSaleState.userLifeBalance).div(new BigNumber(10).pow(preSaleState.lifeDecimals)).toFormat(2) }</strong> } DEATH</dd>
                    <dt>Rate:</dt>
                    <dd>1 : 1 Swap</dd>
                  </dl>
                  { renderUnlockOrApproveOrSwapButton() }
                </div>
              </div>
            </div>
            <div className="column is-two-thirds">
              <div className="box">
                <p className="subtitle has-text-info">PRESALE TIMELINE</p>
                <div className="content">
                  {isLoading ? renderLoading() : (
                    <ol>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(1) ? 'has-text-success' : ''} ${new BigNumber(preSaleState.currentStage).gt(1) ? 'is-line-through' : ''}` }>
                        Sun 23rd 15:00 UTC - PRESALE (EARLY ACCESS 1 $20)
                      </li>
                      <li className={ `${nftSale0Started ? 'is-line-through' : ''}` }>
                        Thu 23th 17:00 UTC - NFT Sale.
                      </li>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(2) ? 'has-text-success' : ''} ${new BigNumber(preSaleState.currentStage).gt(2) ? 'is-line-through' : ''}` }>
                        Mon 24th 15:00 UTC - PRESALE (EARLY ACCESS 2 $23)
                      </li>
                      <li className={ `${new BigNumber(preSaleState.currentStage).eq(3) ? 'has-text-success' : ''} ${lifeEnded ? 'is-line-through' : ''}` }>
                        Tue 25th 15:00 UTC - PRESALE (EARLY ACCESS 3 $25)
                      </li>
                      <li className={ `${lifeEnded ? 'is-line-through' : ''}` }>
                        Wed 26th 15:00 UTC - PRESALE ENDS
                      </li>
                      <li className={ `${nftAirdropStarted ? 'is-line-through' : ''}` }>
                        Thu 27th 14:00 UTC - NFT Airdrop
                      </li>
                      <li className={ `${provideLiquidityStarted ? 'is-line-through' : ''}` }>
                        Thu 27th 15:00 UTC - Liquidity Provided automatically by Contract
                      </li>
                      <li className={ `${lifeSwapStarted ? 'is-line-through' : ''}` }>
                        Thu 27th 16:00 UTC - Swap Life to Death.
                      </li>
                      <li className={ `${masterChefStarted ? 'is-line-through' : ''}` }>
                        Sun 30th 15:00 UTC - Starts Farming
                      </li>
                    </ol>
                  )}
                </div>
              </div>
            </div>
            <div className="column is-two-thirds">
              <div className="box">
                <p className="subtitle has-text-info">HOW IT WORKS</p>
                <div className="content">
                  <ol type="A">
                    <li>Purchase LIFE with USDC using the LIFE PRESALE CONTRACT</li>
                    <li>Stake your LIFE in the DEATH KINGDOM and EARN rewards</li>
                    <li>Swap LIFE for DEATH using the LIFE SWAP CONTRACT</li>
                    <li>HAPPY FARMING!!!</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <LifeBuyModal
        preSaleState={ preSaleState }
        isModalActive={ isLifeBuyModalActive }
        onModalClose={ handleLifeBuyModalClose }
      />
    </>
  );
}

export default PresalePage;
