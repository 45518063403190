import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const HomeNav = () => {
  const location = useLocation();

  return (
    <nav className="section pt-3 pb-0 is-hidden-mobile">
      <div className="container">
        <div className="tabs is-boxed is-centered">
          <ul>
            <li className={ location.pathname === '/dashboard' ? 'is-active' : '' }>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className={ location.pathname === '/presale' ? 'is-active' : '' }>
              <Link to="/presale">Presale</Link>
            </li>
            <li className={ location.pathname === '/anti-bot' ? 'is-active' : '' }>
              <Link to="/anti-bot">Anti-bot</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HomeNav;
