import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import lifeAbi from '../config/abis/life.json';
import lifeSwapAbi from '../config/abis/lifeSwap.json';
import masterChefAbi from '../config/abis/masterchef.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import nftAirdropAbi from '../config/abis/nftAirdrop.json';
import erc20Abi from '../config/abis/erc20.json';
import { getAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

export const fetchPreSale = async () => {
  const walletAddress = await getWalletAddress();

  const lifeAddress = getAddress('life');
  const lifeSwapAddress = getAddress('lifeSwap');
  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
  const tokenAddress = getAddress('usdc');
  const masterChefAddress = getAddress('masterChef');
  const nftAirdropAddress = getAddress('nftAirdrop');

  const lifeCalls = [
    {
      address: lifeAddress,
      name: 'startTime',
    },
    {
      address: lifeAddress,
      name: 'endTime',
    },
    {
      address: lifeAddress,
      name: 'lifePerAccountMaxTotal',
    },
    {
      address: lifeAddress,
      name: 'lifeRemaining',
    },
    {
      address: lifeAddress,
      name: 'decimals'
    },
    {
      address: lifeAddress,
      name: 'balanceOf',
      params: [lifeAddress],
    },
    {
      address: lifeAddress,
      name: 'earlyAccessTwoTime',
    },
    {
      address: lifeAddress,
      name: 'earlyAccessThreeTime',
    },
    {
      address: lifeAddress,
      name: 'earlyAccessOnePrice',
    },
    {
      address: lifeAddress,
      name: 'earlyAccessTwoPrice',
    },
    {
      address: lifeAddress,
      name: 'earlyAccessThreePrice',
    },
  ];

  if (walletAddress !== null) {
    lifeCalls.push({
      address: lifeAddress,
      name: 'allowance',
      params: [
        walletAddress,
        lifeSwapAddress,
      ],
    });
    lifeCalls.push({
      address: lifeAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    lifeCalls.push({
      address: lifeAddress,
      name: 'userLifeTotally',
      params: [walletAddress],
    });
  }

  const lifeSwapCalls = [
    {
      address: lifeSwapAddress,
      name: 'startTime',
    },
  ];

  const erc20Calls = [
    {
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [lifeSwapAddress],
    },
    {
      address: nativeTokenAddress,
      name: 'decimals',
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
  ];

  if (walletAddress !== null) {
    erc20Calls.push({
      address: tokenAddress,
      name: 'allowance',
      params: [
        walletAddress,
        lifeAddress,
      ],
    });
    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    erc20Calls.push({
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
  }

  const masterChefCalls = [
    {
      address: masterChefAddress,
      name: 'startTime',
    }
  ];

  const nftSaleCalls = [];

  for(let i = 0; i < 1; i++) {
    nftSaleCalls.push({
      address: getAddress(`nftSale${i}`),
      name: 'startTime',
    });
  }

  const lifeResults = await multicall(lifeAbi, lifeCalls);
  const lifeSwapResults = await multicall(lifeSwapAbi, lifeSwapCalls);
  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const masterChefResults = await multicall(masterChefAbi, masterChefCalls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);
  const nftAirdropResults = await multicall(nftAirdropAbi, [{
    address: nftAirdropAddress,
    name: 'startTime',
  }]);

  const earlyAccessTwoTime = new BigNumber(lifeResults[6] || 0);
  const earlyAccessThreeTime = new BigNumber(lifeResults[7] || 0);

  const currentTime = Date.now() / 1000;

  let salePrice = new BigNumber(lifeResults[8] || 0);
  let currentStage = new BigNumber(1);

  if (earlyAccessThreeTime.gt(0) && earlyAccessThreeTime.lt(currentTime)) {
    salePrice = new BigNumber(lifeResults[10] || 0);
    currentStage = new BigNumber(3);
  } else if (earlyAccessTwoTime.gt(0) && earlyAccessTwoTime.lt(currentTime)) {
    salePrice = new BigNumber(lifeResults[9] || 0);
    currentStage = new BigNumber(2);
  }

  return {
    firstLoad: false,

    lifeStartTime: new BigNumber(lifeResults[0] || 0).toJSON(),
    lifeEndTime: new BigNumber(lifeResults[1] || 0).toJSON(),
    lifePerAccountMaxTotal: new BigNumber(lifeResults[2] || 0).toJSON(),
    lifeRemaining: new BigNumber(lifeResults[3] || 0).toJSON(),
    lifeDecimals: lifeResults[4] ? lifeResults[4][0] : 0,
    lifeBalance: new BigNumber(lifeResults[5] || 0).toJSON(),

    userLifeSwapAllowance: new BigNumber(lifeResults[11] || 0).toJSON(),
    userLifeBalance: new BigNumber(lifeResults[12] || 0).toJSON(),
    userLifeTotally: new BigNumber(lifeResults[13] || 0).toJSON(),

    salePrice: salePrice.toJSON(),
    currentStage: currentStage.toJSON(),
    earlyAccessTwoTime: earlyAccessTwoTime.toJSON(),
    earlyAccessThreeTime: earlyAccessThreeTime.toJSON(),

    lifeSwapStartTime: new BigNumber(lifeSwapResults[0] || 0).toJSON(),

    lifeSwapBalance: new BigNumber(erc20Results[0] || 0).toJSON(),
    nativeTokenDecimals: erc20Results[1] ? erc20Results[1][0] : 0,
    tokenDecimals: erc20Results[2] ? erc20Results[2][0] : 0,
    userLifeAllowance: new BigNumber(erc20Results[3] || 0).toJSON(),
    userTokenBalance: new BigNumber(erc20Results[4] || 0).toJSON(),
    userNativeTokenBalance: new BigNumber(erc20Results[5] || 0).toJSON(),

    masterChefStartTime: new BigNumber(masterChefResults[0] || 0).toJSON(),

    nftSale0StartTime: new BigNumber(nftSaleResults[0] || 0).toJSON(),
    // nftSale1StartTime: new BigNumber(nftSaleResults[1] || 0).toJSON(),
    // nftSale2StartTime: new BigNumber(nftSaleResults[2] || 0).toJSON(),

    nftAirdropStartTime: new BigNumber(nftAirdropResults[0] || 0).toJSON(),

    soldOut: new BigNumber(lifeResults[3] || 0).eq(0),
  };
}

export const approveBuyLife = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('life'), ethers.constants.MaxUint256);
}

export const approveSwapLife = async () => {
  const tokenContract = await getSignedContract('life', lifeAbi);
  return await tokenContract.approve(getAddress('lifeSwap'), ethers.constants.MaxUint256);
}

export const swapLifeForDeath = async () => {
  const lifeSwapContract = await getSignedContract('lifeSwap', lifeSwapAbi);
  return await lifeSwapContract.swapLifeForDeath();
}

export const buyLife = async (amount) => {
  const lifeContract = await getSignedContract('life', lifeAbi);
  return await lifeContract.buyLife(amount);
}
