import React from 'react';
import BigNumber from 'bignumber.js';

const NftCard = ({ nftData, hideDetails = false, ribbon = '' }) => {
  return (
    <div className={ `box is-relative ${nftData.mergeCount > 0 ? 'important-box' : ''}` }>
      { ribbon !== '' ? (
        <div className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </div>
      ) : null }
      <figure className="image is-4by5 mb-3">
        <img src={ `/images/nfts/generation-${nftData.generation}.png` } alt="Sandman" />
      </figure>
      <p className="title neon-success m-0 has-text-centered">{ nftData.name }</p>
      {hideDetails ? null : (
        <div className="column is-narrow mb-3">
          <div className="field is-grouped is-grouped-multiline is-justify-content-center">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Gen.</span>
                <span className="tag mb-0 is-info">{ nftData.generation }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Exp.</span>
                <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).toFormat(0) }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Boost Stake</span>
                <span className="tag mb-0 is-warning">{ new BigNumber(nftData.boostStake).toFormat(2) }%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NftCard;
