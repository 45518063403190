export const genName = (token) => {
  return [
    'DREAM',
    'DELIRIUM',
    'DESPAIR',
    'DESTRUCTION',
    'DESIRE',
    'DESTINY',
    'DEATH',
    'THE ENDLESS'
  ][token.generation] || 'DREAM';
}

export const nftName = (token) => `${genName(token)} #${token.pid}`;

export const nftDescription = (token) => {
  if (token.generation === 1) {
    return (
      <>
        Delirium is one of the seven Endless, inconceivably powerful beings, Delirium is both lord and personification of all Delirium and Sanity.
        She had god-like powers and absolute power over her realm, which is a chaotic, constantly changing mass of colors and strange objects and shapes.
        She is capable of bringing sanity or madness to any and all within her scope.
      </>
    );
  }

  if (token.generation === 2) {
    return (
      <>
        Despair is the second youngest of the Endless. Her realm is a gray, foggy space filled with rats and mirrors which she uses to look upon people in their despair.
        Despair's sigil is a hooked ring she wears on her left hand which she uses to tear into her flesh.
      </>
    );
  }

  if (token.generation === 3) {
    return (
      <>
        Destruction is the fourth eldest of the Endless. He is the younger brother of Dream. He is also known as Olethros. He is the only one of the Endless to abandon his realm and responsibilities and so is often referred to as The Prodigal. Since he abandoned his realm, there is no sigil in the galleries for him, though he keeps his own gallery(wherein is a sword, his sigil of old).
      </>
    );
  }

  if (token.generation === 4) {
    return (
      <>
        Desire is the third youngest of the Endless and the twin of Despair. The anthropomorphic personification of its name, Desire blends in effortlessly with whatever environment it finds itself in.<br />
        Desire is easily the most casually cruel of the Endless. It seems obsessed with interfering with the affairs of its elder siblings and has a particular animosity toward Dream.<br/>
        The motivation behind its attitude is not clear but seems to be simply a vicious variation on childish one - up - manship. Desire may not be entirely aware of the consequences of its actions but considers any consequences ultimately unimportant.
      </>
    );
  }

  if (token.generation === 5) {
    return (
      <>
        Destiny is the first and eldest of the Endless.
        Like the rest of his siblings, Destiny is neither a mortal being nor a god; he is the personification of Destiny itself.
        As a blind man, Destiny appears as a man with a hooded cloak of an indeterminate color of brown, gray, and sometimes purple. He is chained to a book, which contains the story of everyone and everything.
        Sometimes things appear in his book before they happen, and sometimes as they happen.
        His duty is merely to read and observe through his book, and he carries it out assiduously.
      </>
    );
  }

  if (token.generation === 6) {
    return (
      <>
        Death is the second oldest of the Endless and the elder sister of Dream.
        Death appears as an young, attractive, slim woman of average height in her early to mid 20's. She has an Eye of Horus painted under one of her eyes.
        Death despite her job description has always been the perkiest of the Endless. Death is warm and caring and she has taken the role of the peace-maker among the siblings.
        Death is perhaps the most powerful member of the Endless and is certainly one of the most powerful beings in the universe, being the anthropomorphic embodiment of a universal fact.
      </>
    );
  }

  if (token.generation === 7) {
    return (
      <>
        The Endless are the anthropomorphic embodiments of powerful natural forces they embody. The Endless are a dysfunctional family, and do not often get along with each other. They are known to be the children of the cosmic entities Night and Time. From oldest to youngest they are Destiny, Death, Dream, Destruction, Desire, Despair, and Delirium, who was previously known as Delight.
      </>
    );
  }

  return (
    <>
      Dream was born once the first sentient life forms capable of dreaming appeared in the universe.<br />
      As one of the seven infinite and inconceivably powerful beings, Dream is the lord of all dreams, stories and everything that does not exist in reality.<br />
      Through his tools of power, he possesses absolute control over the dreams, nightmares and even the insomnia of all existence.
    </>
  );
}

export const experienceScale = (experience) =>  experience.div(100).toNumber();
