import React from 'react';
import BigNumber from 'bignumber.js';

const NftPreviewModal = ({ nftData, isNftPreviewModalActive, onNftPreviewModalClose }) => {
  return (
    <div className={`modal ${isNftPreviewModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">NFT Preview</p>
          <button className="delete" aria-label="close" onClick={ onNftPreviewModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-mobile">
            <div className="column">
              <figure className="image is-4by5">
                <img src={ `/images/nfts/generation-${nftData.generation}.png` } alt={ nftData.name } />
              </figure>
            </div>
            <div className="column">
              <p className="title is-size-4 has-text-success">{ nftData.name }</p>
              <p className="subtitle is-size-6">Generation: { nftData.generation }</p>

              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Strength:</span>
                <span className="tag is-danger is-one-third">{ nftData.strength }</span>
              </div>
              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Agilitty:</span>
                <span className="tag is-danger is-one-third">{ nftData.agility }</span>
              </div>
              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Endurance:</span>
                <span className="tag is-danger is-one-third">{ nftData.endurance }</span>
              </div>
              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Intelligence:</span>
                <span className="tag is-danger is-one-third">{ nftData.intelligence }</span>
              </div>
              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Magic:</span>
                <span className="tag is-danger is-one-third">{ nftData.magic }</span>
              </div>
              <div className="tags mb-1 has-addons is-fullwidth">
                <span className="tag is-warning is-two-thirds">Wisdom:</span>
                <span className="tag is-danger is-one-third">{ nftData.wisdom }</span>
              </div>
              <div className="field is-grouped is-grouped-multiline">
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-black">Experience:</span>
                    <span className="tag is-info">{ new BigNumber(nftData.experience).toFormat(0) }</span>
                  </div>
                </div>

                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-black">Boost Stake:</span>
                    <span className="tag is-success">{ new BigNumber(nftData.boostStake).toFormat(2) }%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            className="button"
            onClick={ onNftPreviewModalClose }
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

export default NftPreviewModal;
