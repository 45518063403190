import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PoolHarvestSection from '../PoolHarvestSection';
import PoolHarvestNftSection from '../PoolHarvestNftSection';
import PoolStakeSection from '../PoolStakeSection';
import PoolStakeNftSection from '../PoolStakeNftSection';
import PoolCardFooterSection from './components/PoolCardFooterSection';

import './PoolCard.css';

// const ZERO = new BigNumber(0);

const PoolCard = ({ poolData, handleWithdrawModalOpen, handleDepositModalOpen, handleDepositNftModalOpen, handleNftPreviewModalOpen }) => {
  const poolImageSrc = () => `/images/farms/${poolData.name}.png`.replace(/\s/g, '-').toLowerCase();

  const [started, setStarted] = useState(false);
  const [ended, setEnded] = useState(true);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;

      setStarted(prevState => prevState || new BigNumber(poolData.startTime).lt(currentTime));
      setEnded(prevState => !(!prevState || new BigNumber(poolData.endTime).gt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [poolData.startTime, poolData.endTime, setStarted, setEnded]);

  return (
    <div className="column is-half-tablet is-one-third-desktop">
      <div className={ `box is-relative ${ended ? '' : 'important-box'}` }>
        <div className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <figure className="image is-64x64">
                <img src={ poolImageSrc() } alt={ poolData.name } />
              </figure>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="is-flex is-flex-direction-column is-align-items-flex-end">
                <h5 className="is-size-5 has-text-weight-semibold">{ poolData.name }</h5>
                <span className="tag is-rounded is-warning">
                  { poolData.category }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="level is-mobile mb-1">
          <div className="level-left">
            <div className="level-item">
              APR
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <h6 className="is-size-6 has-text-weight-semibold">
                { new BigNumber(poolData.apr).times(100).toFormat(2) }%
              </h6>
            </div>
          </div>
        </div>
        <PoolHarvestSection poolData={ poolData } />
        <PoolHarvestNftSection poolData={ poolData } />
        <PoolStakeSection poolData={ poolData } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } ended={ ended } />
        <PoolStakeNftSection poolData={ poolData } handleDepositNftModalOpen={ handleDepositNftModalOpen } handleNftPreviewModalOpen={ handleNftPreviewModalOpen } started={ started } ended={ ended } />
        {!started ? (
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={ faStopwatch } />
                  </span>
                  Starts at:
                </span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h6 className="is-size-6 has-text-weight-semibold">
                  <Countdown date={ new BigNumber(poolData.startTime).times(1000).toNumber() } />
                </h6>
              </div>
            </div>
          </div>
        ) : null}
        {!ended ? (
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={ faStopwatch } />
                  </span>
                  Ends at:
                </span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h6 className="is-size-6 has-text-weight-semibold">
                  <Countdown date={ new BigNumber(poolData.endTime).times(1000).toNumber() } />
                </h6>
              </div>
            </div>
          </div>
        ) : null}
        <hr />
        <PoolCardFooterSection poolData={ poolData } />
      </div>
    </div>
  );
}

export default PoolCard;
