import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from './components/header/Header';
import Content from './components/content/Content';
import Footer from './components/footer/Footer';
import ToastList from './components/toastList/ToastList';

import './Layout.css';

const Layout = () => {
  return (
    <Router>
      <div id="bg" />
      <Header />
      <Footer />
      <Content />
      <ToastList />
    </Router>
  );
}

export default Layout;
