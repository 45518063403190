import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftBuyModal from './NftBuyModal';
import NftInfo from './components/NftInfo';

import { WalletContext } from '../../context/wallet';
import { ToastContext } from '../../context/toast';

import { fetchNftSale, approveNftSale } from '../../blockchain/nft';
import { genName, nftName } from '../../utils/nft';

const NftSalePage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [gen, setGen] = useState(0);
  const [pendingTx, setPendingTx] = useState(false);
  const [nftSaleState, setNftSaleState] = useState({
    loading: false,
    firstLoad: true,
    userNftBalance: 0,
    userBalance: 0,
    tokenDecimals: 0,
    items: [],
  });
  const [isNftBuyModalActive, setIsNftBuyModalActive] = useState(false);

  const handleNftBuyModalClose = () => setIsNftBuyModalActive(false);
  const handleNftBuyModalOpen = () => setIsNftBuyModalActive(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-2.png)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncNftSale = async () => {
      setNftSaleState(prevState => ({ ...prevState, loading: true }));
      const nftSaleResult = await fetchNftSale();
      setNftSaleState(prevState => ({ ...prevState, ...nftSaleResult, loading: false }));
    }

    const intervalId = setInterval(syncNftSale, 5000);

    syncNftSale();

    return () => clearInterval(intervalId);
  }, [setNftSaleState]);

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveNftSale('usdc', `nftSale${gen}`);
      await tx.wait();
      addToast('NFT Sale token approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Sale token approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderUnlockApproveOrBuyButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    if (!isLoading() && new BigNumber(nftSaleState.items[gen]?.nftCardsRemaining || 0).eq(0)) {
      return (
        <button type="button" disabled className="button is-primary is-fullwidth">SOLD OUT</button>
      );
    }

    if (new BigNumber(nftSaleState.items[gen]?.userAllowance || 0).eq(0)) {
      return (
        <button
          type="button"
          disabled={ pendingTx || isLoading() }
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
          onClick={ handleApprove }
        >
          APPROVE USDC
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() }
        className="button is-primary is-fullwidth"
        onClick={ () => handleNftBuyModalOpen() }
      >
        Buy
      </button>
    );
  }

  const onGenChange = (evt) => {
    const { target } = evt;
    setGen(Number(target.value));
  }

  const isLoading = () => nftSaleState.firstLoad && nftSaleState.loading;

  return (
    <>
      <NftNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title neon-success is-size-1">NFT Cards Sale</p>
            {/* <p className="subtitle is-size-3"><span className="has-text-success">NFT's Cards</span> power will be unique and generated randomly.</p> */}
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="has-text-centered mb-5">
            <div className="select is-rounded">
              <select value={ gen } onChange={ onGenChange }>
                {nftSaleState.items.map(item => (<option key={ `option-${item.gen}` } value={ item.gen }>{ genName({ generation: item.gen }) }</option>))}
              </select>
            </div>
          </div>
          <div className="columns is-multiline is-justify-content-center">
            <div className="column is-half-desktop">
              <div className="box">
                <p className="title has-text-info">NFT Cards Sale</p>
                <div className="level is-mobile mb-1">
                  <div className="level-left">
                    <div className="level-item">
                      NFTs Remaining:
                    </div>
                  </div>
                  <div className="level-left">
                    <div className="level-item">
                      <h6 className="has-text-weight-semibold has-text-success is-size-6">
                        { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : (new BigNumber(nftSaleState.items[gen]?.nftCardsRemaining || 0).eq(0) ? 'SOLD OUT' : new BigNumber(nftSaleState.items[gen]?.nftCardsRemaining || 0).toFormat(0)) }
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="level is-mobile mb-1">
                  <div className="level-left">
                    <div className="level-item">
                      Your NFT balance:
                    </div>
                  </div>
                  <div className="level-left">
                    <div className="level-item">
                      <h6 className="has-text-weight-semibold has-text-success is-size-6">{ isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : new BigNumber(nftSaleState.userNftBalance).toFormat(0) }</h6>
                    </div>
                  </div>
                </div>
                <div className="level is-mobile mb-1">
                  <div className="level-left">
                    <div className="level-item">
                      Your USDC balance:
                    </div>
                  </div>
                  <div className="level-left">
                    <div className="level-item">
                      <h6 className="has-text-weight-semibold has-text-success is-size-6">${ isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : new BigNumber(nftSaleState.userBalance).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(2) }</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box">
                <p className="subtitle">
                  BUY NFT with USDC, only <strong className="has-text-success ml-1">${ new BigNumber(nftSaleState.items[gen]?.salePrice || 0).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(0) }</strong>
                </p>
                { renderUnlockApproveOrBuyButton() }
              </div>
              <NftInfo />
            </div>
            <div className="column is-one-third">
              <NftCard nftData={{
                name: nftName({ pid: gen, generation: gen }),
                pid: gen,
                experience: 1920,
                generation: gen,
                boostStake: 2.87,
              }} />
            </div>
          </div>
        </div>
      </main>
      <NftBuyModal
        gen={ gen }
        nftSaleState={ nftSaleState }
        isNftBuyModalActive={ isNftBuyModalActive }
        onNftBuyModalClose={ handleNftBuyModalClose }
      />
    </>
  );
}

export default NftSalePage;
