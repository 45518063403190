import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';
import NftPreviewModal from './components/NftPreviewModal';

import { fetchNftRanking } from '../../blockchain/nft';

const NftRankingPage = () => {
  const [nftRankingState, setNftRankingState] = useState({
    loading: false,
    firstLoad: true,
    items: [],
  });
  const [selected, setSelected] = useState(0);
  const [isNftPreviewModalActive, setIsNftPreviewModalActive] = useState(false);

  const handleNftPreviewModalClose = () => setIsNftPreviewModalActive(false);
  const handleNftPreviewModalOpen = (evt, index) => {
    evt.preventDefault();
    setSelected(index);
    setIsNftPreviewModalActive(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-2.png)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncNftRanking = async () => {
      setNftRankingState(prevState => ({ ...prevState, loading: true }));
      console.log('Refresh...')
      const rs = await fetchNftRanking();
      setNftRankingState(prevState => ({ ...prevState, ...rs, loading: false, firstLoad: false }));
    }

    const intervalId = setInterval(syncNftRanking, 300000); // 5 minutes

    syncNftRanking();

    return () => clearInterval(intervalId);
  }, [setNftRankingState]);

  return (
    <>
      <NftNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title is-size-1 neon-success">NFT Ranking</p>
            <p className="subtitle is-size-3">Top User's Experienced <span className="has-text-success">NFT Card</span> Ranking.</p>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          {nftRankingState.firstLoad && nftRankingState.loading ? (
            <div className="box">
              <div className="has-text-centered">
                <span className="icon-text is-align-items-center">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faCog } spin size="2x" />
                  </span>
                  <span>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div className="card p-0">
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th className="has-text-right"><abbr title="Position">Pos</abbr></th>
                    <th className="has-text-left">ID</th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Generation">Generation</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Strength">Strength</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Agility">Agility</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Endurance">Endurance</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Intelligence">Intelligence</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Magic">Magic</abbr></th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Wisdom">Wisdom</abbr></th>
                    <th className="has-text-centered"><abbr title="Experience">Experience</abbr></th>
                  </tr>
                </thead>
                <tbody>
                  {nftRankingState.items.map((nft, i) => (
                    <tr key={ nft.name }>
                      <td className="has-text-right">{ i + 1 }</td>
                      <td className="has-text-left">
                        <a href="/" onClick={ (evt) => handleNftPreviewModalOpen(evt, i) }>{ nft.name }</a>
                      </td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.generation }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.strength }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.agility }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.endurance }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.intelligence }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.magic }</td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.wisdom }</td>
                      <td className="has-text-centered">
                        <strong className="has-text-success">{ new BigNumber(nft.experience).toFormat(0) }</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>

      {isNftPreviewModalActive ? (
        <NftPreviewModal
          nftData={ nftRankingState.items[selected] }
          isNftPreviewModalActive={ isNftPreviewModalActive }
          onNftPreviewModalClose={ handleNftPreviewModalClose }
        />
      ) : null}
    </>
  );
}

export default NftRankingPage;
