const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    // {
    //   pid: 2,
    //   address: '0x2F1A81d3Daae4a6d2e592a97e81B364aA5D9C4c9',
    //   stakedToken: 'death',
    //   rewardToken: 'dai',
    //   name: 'DEATH DAI KINGDOM',
    //   projectLink: 'https://makerdao.com/en/',
    //   category: 'CORE',
    //   version: 'v1',
    // },
    {
      pid: 1,
      address: '0xDe34A39F5700a58E03473FA866D1bc36c7F4d81e',
      stakedToken: 'death',
      rewardToken: 'dai',
      name: 'DEATH DAI KINGDOM',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
      stakedTokenDefaultPrice: 20.00,
    },
  ],
};

export default poolInitialState;
