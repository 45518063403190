import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftInfo from './components/NftInfo';

import { NftContext } from '../../context/nft';
import { fetchMyNfts } from '../../blockchain/nft';

const NftHomePage = () => {
  const { nftState, setNftState } = useContext(NftContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-2.png)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncMyNfts = async () => {
      setNftState(prevState => ({ ...prevState, loading: true }));
      const nftResult = await fetchMyNfts();
      setNftState(prevState => ({ ...prevState, ...nftResult, loading: false }));
    }

    const intervalId = setInterval(syncMyNfts, 10000);

    syncMyNfts();

    return () => clearInterval(intervalId);
  }, [setNftState]);

  const isLoading = () => nftState.firstLoad && nftState.loading;

  const renderResults = () => {
    if (isLoading()) {
      return (
        <div className="box">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    if (nftState.myNfts.length > 0) {
      return nftState.myNfts.map(nftData => (
        <div key={ `nft-${nftData.generation}-${nftData.pid}` } className="column is-one-third-desktop">
          <NftCard nftData={ nftData } />
        </div>
      ));
    }

    return (
      <NftCard
        nftData={{
          pid: 2110,
          experience: 1920,
          generation: 0,
          boostStake: 2.87
        }}
        ribbon="ON SALE"
      />
    );
  }

  return (
    <>
      <NftNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title is-size-1 neon-success">My NFT Cards</p>
            {/* <p className="subtitle is-size-3"><span className="has-text-success">NFT Cards</span> power will be unique and generated randomly.</p> */}
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="columns is-multiline is-justify-content-center">
            { renderResults() }
          </div>
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <NftInfo />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NftHomePage;
