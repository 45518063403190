import React, { useEffect, useContext } from 'react';
import HomeNav from '../home/components/HomeNav';

import CountdownSection from './components/CountdownSection';
import FarmsStakingSection from './components/FarmsStakingSection';
import TokenStatsSection from './components/TokenStatsSection';
import TotalValueLockedSection from './components/TotalValueLockedSection';
import NewsSection from './components/NewsSection';
import EarnUpToSection from './components/EarnUpToSection';

import { FarmsContext } from '../../context/farms';
import { fetchFarms } from '../../blockchain/farms';
import { PoolsContext } from '../../context/pools';
import { fetchPools } from '../../blockchain/pools';
import { VaultsContext } from '../../context/vaults';
import { fetchVaults } from '../../blockchain/vaults';

import './DashboardPage.css';

function DashboardPage() {
  const { farmState, setFarmState } = useContext(FarmsContext);
  const { poolState, setPoolState } = useContext(PoolsContext);
  const { vaultState, setVaultState } = useContext(VaultsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('bg').style.backgroundImage = 'url(/images/bg/bg-0.jpg)';

    return () => document.getElementById('bg').style.backgroundImage = null;
  }, []);

  useEffect(() => {
    const syncData = async () => {
      setFarmState(prevState => ({ ...prevState, loading: true }));
      const farmsResult = await fetchFarms();
      setFarmState(prevState => ({ ...prevState, ...farmsResult, loading: false }));

      setPoolState(prevState => ({ ...prevState, loading: true }));
      const poolsResult = await fetchPools();
      setPoolState(prevState => ({ ...prevState, ...poolsResult, loading: false }));

      setVaultState(prevState => ({ ...prevState, loading: true }));
      const vaultsResult = await fetchVaults();
      setVaultState(prevState => ({ ...prevState, ...vaultsResult, loading: false }));
    }

    const intervalId = setInterval(syncData, 10000);

    syncData();

    return () => clearInterval(intervalId);
  }, [setFarmState, setPoolState, setVaultState]);

  return (
    <>
      <HomeNav />
      <header className="hero is-small" style={{ backgroundColor: 'transparent' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title is-size-1 neon-success">Welcome to DEATH</p>
            <p className="subtitle is-size-3">
              The first Polygon Community <span className="has-text-success">Owned AMM</span>.<br />
              To get there, you need to venture into the Sandman Universe and its 7 kingdoms.
            </p>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <CountdownSection />
          <div className="columns is-multiline">
            <div className="column is-half is-flex">
              <FarmsStakingSection farmState={ farmState } />
            </div>
            <div className="column is-half">
              <NewsSection />
            </div>
            <div className="column is-half">
              <TokenStatsSection farmState={ farmState } />
            </div>
            <div className="column is-half is-flex">
              <TotalValueLockedSection farmState={ farmState } poolState={ poolState } vaultState={ vaultState } />
            </div>
          </div>
          <EarnUpToSection farmState={ farmState } poolState={ poolState } vaultState={ vaultState } />
        </div>
      </main>
    </>
  );
}

export default DashboardPage;
