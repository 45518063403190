import React from 'react';

import { Timeline } from 'react-twitter-widgets';

const NewsSection = () => {
  return (
    <div className="box">
      <h3 className="title has-text-info">Announcements</h3>
      <div className="mx-auto">
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'sandman_finance',
          }}
          options={{
            height: '300',
            chrome: 'noheader, nofooter',
            width: '600',
            theme: 'dark',
          }}
        />
      </div>
    </div>
  );
}

export default NewsSection;
