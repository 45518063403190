const farmInitialState = {
  loading: false,
  firstLoad: true,
  nativeTokenPrice: 0,
  nativeTokenPriceDefault: false,
  tvl: 0,
  tnl: 0,
  maxFarmApr: 0,
  tokenTotalSupply: 0,
  tokenTotalBurned: 0,
  tokenPerSecond: 0,
  tokenMaximumSupply: 0,
  farms: [
    // FARMS
    {
      pid: 1,
      token: 'death',
      quoteToken: 'matic',
      isTokenOnly: false,
      name: 'DEATH-WMATIC LP',
      swap: 'QuickSwap',
    },
    {
      pid: 0,
      token: 'death',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'DEATH-USDC LP',
      swap: 'QuickSwap',
    },
    {
      pid: 3,
      token: 'matic',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'WMATIC-USDC LP',
      swap: 'QuickSwap',
    },
    {
      pid: 4,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-USDT LP',
      swap: 'QuickSwap',
    },
    {
      pid: 5,
      token: 'mai',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-MAI LP',
      swap: 'QuickSwap',
    },
    // POOLS
    {
      pid: 2,
      token: 'death',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DEATH',
      swap: 'QuickSwap',
    },
    {
      pid: 15,
      token: 'destiny',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESTINY',
      swap: 'QuickSwap',
    },
    {
      pid: 14,
      token: 'desire',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESIRE',
      swap: 'QuickSwap',
    },
    {
      pid: 13,
      token: 'destruction',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESTRUCTION',
      swap: 'QuickSwap',
    },
    {
      pid: 12,
      token: 'despair',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DESPAIR',
      swap: 'QuickSwap',
    },
    {
      pid: 11,
      token: 'delirium',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DELIRIUM',
      swap: 'QuickSwap',
    },
    {
      pid: 6,
      token: 'matic',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WMATIC',
      swap: 'QuickSwap',
    },
    {
      pid: 9,
      token: 'usdc',
      quoteToken: 'matic',
      isTokenOnly: true,
      name: 'USDC',
      swap: 'QuickSwap',
    },
    {
      pid: 7,
      token: 'mai',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'MAI',
      swap: 'QuickSwap',
    },
    {
      pid: 8,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'USDT',
      swap: 'QuickSwap',
    },
    {
      pid: 10,
      token: 'dai',
      quoteToken: 'matic',
      isTokenOnly: true,
      name: 'DAI',
      swap: 'QuickSwap',
    },
  ],
}

export default farmInitialState;
